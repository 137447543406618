.schedule-scroll {
    scrollbar-width: thin;
}
.schedule-scroll::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

.schedule-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.schedule-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.schedule-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.application-modal .ant-modal-close-icon > svg:nth-child(1) {
    color: black;
  }
@media (min-width: 960px) {
    .schedule-mobile {
        display: none;
    }
    .title-card {
        margin-left: -22px;
    }
}

@media (max-width: 959px) {
    .schedule-desktop {
        display: none;
    }
    .title-card {
        width: 100%;
    }
}

.summary-description {
    overflow-wrap: break-word;
    word-wrap: break-word;

    /*word-break: break-all;*/
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.summary-description ol {
    margin-left: 20px;
}

.breaker {
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-all;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.user-name {
    overflow-wrap: break-word;
    word-wrap: break-word;

    /*word-break: break-all;*/
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

 .zoom {
    cursor: pointer;
    /* transition: transform .1s; */
}
/*
.zoom:hover {
    transition: transform .1s;
    -webkit-transform: scale3d(1.06, 1.06, 1);
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
} */

.startup-box-view {
    display: flex;
    margin-top: 45px;
    width: auto;
}

.startup-box-view-item:nth-of-type(1) {
    padding-right: 15px;
    background-clip: padding-box;
}

.startup-box-view-item:nth-of-type(2) {
    padding-left: 15px;
    background-clip: padding-box;
}

@media (min-width: 850px) {
    .startup-card {
        max-width: 600px;
    }
}

@media (max-width: 850px) {
    .startup-card {
        max-width: 850px;
    }
}

.card-name {
    color: #282828;
    transition: .08s;
}

.card-name:hover {
    color: #6755CE;
    transition: .08s;
}

@media (min-width: 420px) {
    .share-icon {
        font-size: 32px;
        color: #F00;
    }
}

@media (max-width: 419px) {
    .share-icon {
        font-size: 32px;
        color: #F0F;
    }
}
