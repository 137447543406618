.link{
    color: var(--primary-blue)
}

.link:hover{
    cursor: pointer;
}

.rsscontainer{
    font-size: 16px;
    font-weight: 600;
}

.rssfeedtitle{
    font-weight: 900;
    margin-top: 100px;
}