.Idea-card {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #d1d1d1;
  width: 100%;
}

.EventTag-card {
  display: inline-block;
  background: var(--primary-blue);
  padding: 2px 4px;
  color: #fff;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 13px;
  float: right;
}

.Idea-image {
  position: relative;
}

.Idea-image i {
  position: absolute;
  top: 20px;
  right: 28px;
  font-size: 1.5rem;
  background-color: white;
  opacity: 0.6;
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s;
}

.Idea-image:hover i {
  opacity: 1;
  box-shadow: 0px 0px 15px #0000000f;
  transition: all 0.1s;
}

.Idea-image img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.Idea-body {
  padding: 1em;
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.Idea-title {
  font-weight: 900;
  font-size: 18px;
  word-wrap: break-word;
}
.title-with-button {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 2px; */
  width: 100%;
}
.button-holder {
  position: static;
  top: 0px;
}
.title-with-button .canJoin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  width: 151px;
  height: 32px;
  /* primary */
  background: #7c6de4;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  border: none;
}
.title-with-button .leave {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  width: 151px;
  height: 32px;
  /* primary */
  background: #7c6de4;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  border: none;
}
.title-with-button .withdraw {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  width: 160px;
  height: 32px;
  /* primary */
  background: #ffffff;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  color: #7c6de4;
  border: 2px solid #7c6de4;
  box-sizing: border-box;
  border-radius: 4px;
}
.Idea-card-title {
  font-size: 16px;
  font-weight: 900;
  margin: 1.5em auto;
}
.Idea-description {
  font-weight: light;
  font-size: 14px;
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.join-button-right {
  position: relative;
  left: 440px;
  top: -30px;
  display: block;
}
.join-button-right-button {
  background: #7c6de4;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0em;
  width: 152px;
  height: 32px;
}

.Idea-interests {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.6em;
}
.Idea-title-subtitle {
  display: flex;
  flex-direction: column;
}
.Idea-card-top {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

/* .Idea-interests {
	font-weight: bold;
} */

.Idea-interests i {
  margin-left: 10px;
  font-size: 1.3em;
}

.Idea-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.1em;
}
.Idea-user h3 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
}
.Idea-user-image img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.Idea-user-details {
  margin-left: 10px;
}

.Idea-user-details a {
  color: inherit;
}

.Idea-user-name {
  font-weight: 900;
  font-size: 12px;
}

.Idea-user-role {
  font-weight: light;
  font-size: 8px;
}

.Idea-social {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3em;
  font-weight: bold;
  font-size: 13px;
}

.Idea-social-rating i {
  color: var(--primary-blue);
  margin-right: 4px;
}

.Idea-social-comments {
  margin-left: auto;
  margin-right: 30px;
  position: relative;
}

.Idea-social-comments::after {
  content: "";
  position: absolute;
  background-color: #595959;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  top: 10px;
  right: -18px;
}

.Idea-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 1.1em;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.059);
  border-radius: 4px;
  overflow: hidden;
  font-size: 1.2rem;
}

.Idea-action {
  flex: 1;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  color: var(--primary-blue);
}

.Idea-action:not(.disabled):hover {
  background-color: var(--primary-blue);
  color: white;
}

.Idea-action.comment.disabled,
.Idea-action.share.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: rgb(228, 228, 228);
}

.Idea-action-rating .ant-rate-star-full i {
  color: var(--primary-blue);
}

.Idea-action-rating .ant-rate-star-zero i {
  color: #d3ccff;
}

.ant-rate-star-focused i {
  color: #d3ccff;
}

.share-menu-text {
  font-weight: 300;
  text-transform: uppercase;
}

.Idea-interest-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-right: 4px;
}

.Ideas-form input,
.Ideas-form .ant-select-selection {
  border-color: #595d6f;
}

.Ideas-form textarea.ant-input {
  border-color: #595d6f;
  padding: 1.3em;
}

.Ideas-form textarea::placeholder {
  font-size: 1rem;
}

.aditional-infos {
  margin-top: 20px;
}

.aditional-infos h3 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.aditional-infos .social-link-button {
  padding: 0;
}
@media (max-width: 512px) {
  .Ideas-top-container {
    padding: 20px 25px;
  }

  .Idea-action {
    padding-left: 2px;
    padding-right: 2px;
  }

  .Idea-social {
    font-size: 12px;
  }

  .Idea-social-rating {
    /* flex-basis: 100%; */
    margin-bottom: 5px;
  }

  .Idea-social-comments {
    margin-left: unset;
  }

  .Idea-actions {
    margin-top: 11px;
  }
}
