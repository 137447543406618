.footer-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    left: 0px;
    bottom: 0px;
    background: #6755CE;

}

.footer-upper-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 64px 0px;
    gap: 80px;
    max-width: 80%;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.footer-bottom-box {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-between;
    padding: 48px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

}

.footer-bottom-box-desc{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF !important;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.partners-of-pozi-no-partner :hover{
    color: var(--primary-blue) !important;
}
.footer-bottom-box > div > a > img {
    width:32px;
    height:32px;
    margin-left: 8px;
    margin-right: 8px;
}
.footer-upper-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 24px;
}

.footer-links>h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.footer-links>div>a {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.footer-logo-box {
    max-width: 18%;
}
.footer-logo-box > img{
    max-width: 40%;
}

@media (max-width: 1390px) {
    .footer-upper-box {
        gap: 50px;
    }
}

@media (max-width: 1200px) {
    .footer-upper-box {
        max-width: 95%;
    }
}

@media (max-width: 1050px) {
    .footer-upper-box {
        flex-wrap: wrap;
        width: 80%;
        justify-content: space-around;
    }

    .footer-logo-box {
        width: 100%;
        text-align: center;
        max-width: unset;
    }
    .footer-links{
        min-width: 27%;
    }
    
}

@media (max-width: 700px) {
    .footer-bottom-box{
        flex-direction: column-reverse;
    }
    .footer-social-icons{
        margin-bottom: 25px;
    }
}

@media (max-width: 485px) {
    .footer-links{
        width: 60%;
    }
}