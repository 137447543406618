
.add-new-userSearch{
  margin-bottom: 8px;
}
.add-new-inputField{
  margin-bottom: 8px;
}
.add-new-textarea div textarea{
    padding: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #282828;
    min-height: 100px;
    max-height: 100px;
}
.error{
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  color: rgb(245, 34, 45);
}
.add-new-member-button span{
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 28px;
  color: #FFFFFF;
  margin: 0px 10px
}