.roles_holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
  gap: 24px;
}
.role {
  width: 282px;
  height: 152px;
  text-align: center;
  box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 5%);
}
.role:hover {
  background-color: var(--primary-blue);
  color: white;
}
.selected_role {
  width: 282px;
  height: 152px;
  text-align: center;
  box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 5%);
  background-color: var(--primary-blue);
}
.role_content_holder {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.role_image {
  margin-top: 24px;
  height: 64px;
  width: 64px;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  background-position: center;
}
.role_text {
  margin-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}
.role_text_selected {
  margin-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color:white
}
.title_text {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #262626;
}
.not_selected_icon{
  filter: invert(1);
}
.selected_icon{
  filter: invert(0);
}
.role:hover .selected_icon {
  filter: invert(1);

}

