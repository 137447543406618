.Message-element {
    padding: 12px;
    border-bottom: 2px solid #f1f1f1;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Message-element:hover {
    background: #f1f1f1;
}

.Message-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 230px;
    display: inline-block;
}

.Message-textBox {
    display: flex;
    justify-content: space-between;
    width: 340px;
    padding-left: 12px;
}

.Message-from-other, .Message-from-me {
    width: 100%;
}

.Message-from-me {
    float: right;
}

.Message-from-other {
    float: left;
}

.Message-innerText {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 6px 10px;
    margin: 4px 0;
    width: auto;
    max-width: 70%;
    overflow-wrap: break-word;
    word-break: break-word;
}

.Message-from-me .Message-innerText {
    float: right;
    text-align: right;
    background: #333;
    color: #fff;
}

.Message-from-other .Message-innerText {
    float: left;
    text-align: left;
}

.Message-sentDate {
    width: 100%;
}

.Message-from-me .Message-sentDate {
    text-align: right;
}

.Message-sender {
    width: 100%;
    display: flex;
    align-items: center;
    float: left;
    margin-top: 8px;
}

.Message-sender a {
    color: unset;
}

.Message-seen .Message-text {
    font-weight: bold;
}

.Message-seen-radius {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: transparent;
    margin-top: 5px;
}

.Message-seen .Message-seen-radius {
    background-color: #6755ce;
}

.Message-head {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
}

img.Message-list-profile, img.Message-box-profile, img.Message-content-profile {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
}

img.Message-box-profile, img.Message-content-profile {
    margin-right: 8px;
}

img.Message-content-profile {
    width: 24px;
    height: 24px;
}

.Messages-list {
    max-height: calc(100vh - 244px);
    overflow-y: auto;
    min-height: calc(100vh - 244px);
}

.Messages-uniqeScrollbar::-webkit-scrollbar {
    width: 2px;
}

.Messages-uniqeScrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.Messages-uniqeScrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

.MessageBox {
    width: 564px;
    padding: 32px 0;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);
    justify-content: space-between;
}

.MessageBox .Messages {
    overflow-y: auto;
    padding: 0 32px;
    min-height: 100px;
}

.Messages-boxShadow {
    box-shadow: 1px 2px 2px rgba(0,0,0,0.04),
    2px 4px 4px rgba(0,0,0,0.04),
    4px 8px 8px rgba(0,0,0,0.04),
    8px 16px 16px rgba(0,0,0,0.04),
    16px 32px 32px rgba(0,0,0,0.04),
    32px 64px 64px rgba(0,0,0,0.04);
}

.Messages-attachment {
    color: #6755ce;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    width: 136px;
    text-decoration: underline;
    text-underline-position: under;
    margin-top: 12px;
}

.icon-add-attachment {
    height: 20px;
    width: 20px;
}

.icon-remove {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
    cursor: pointer
}

.icon-attachment {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-right: 4px;
}

.MessageList-box {
    width: 400px;
    min-height: 100px;
    border-radius: 12px;
    padding: 12px 0;
}

.MessageList {
    width: 400px;
}

.Message-selected {
    background-color: rgb(242, 242, 242);
}

.MessageBox-main {
    width: 564px
}

.MessagesTextLabel {
    display: none;
}

.MessageBox-main textarea {
    display: inline-block;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px
}

@media (max-width: 600px) {
    .MessageList {
        display: none;
        width: 100%;
    }
    .MessageBox-main {
        display: none;
        width: 100%;
    }
    .MessageList.open {
        display: block;
    }
    .MessageBox-main.open {
        display: block;
    }
    .MessageList-box {
        width: 100%;
    }
    .Message-textBox {
        width: 100%;
    }
    .Message-text {
        width: calc(100vw - 190px);
    }
    .MessageBox {
        width: 100%;
    }
    .MessagesTextLabel {
        display: block;
        cursor: pointer;
        text-decoration: underline;
    }
    .MessageBox-main textarea {
        max-width: calc(100vw - 190px)
    }
    .Message-innerText {
        max-width: 80%;
    }
}