.Registration {
  padding: 3em 0;
}

.Registration-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  margin: 0.6em;
}

.Registration-steps-line {
  width: 40%;
  margin: 0 auto;
}

.Registration-error {
  margin-top: 8px;
  color: red;
}

.steps-content {
  max-width: 1300px;
  margin: auto;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
}

.step-title {
  font-size: 1.4rem;
  margin: 1em 0 2em;
  font-weight: bold;
  text-align: center;
}

.step-userinfo-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #595d6f;
  border-radius: 3px;
}

.ImageUploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ImageUploader-info {
  font-size: 0.7rem;
}

.ImageUploader-error {
  font-size: 0.8rem;
  color: red;
}

.ant-upload.ant-upload-select-picture-card {
  margin: 0;
  border-radius: 50%;
}

.step-userinfo-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ProfileEditor-form .ant-select-no-arrow .ant-select-selection-selected-value,
.step-userinfo-inputs
  .ant-select-no-arrow
  .ant-select-selection-selected-value {
  margin-left: 18px;
  margin-top: 4px;
  font-weight: 900;
  font-size: 12px;
}

.ProfileEditor-form .step-userinfo-input .ant-select-selection__placeholder,
.step-userinfo-inputs .step-userinfo-input .ant-select-selection__placeholder {
  margin-left: 18px;
  font-size: 12px;
  color: #262626 !important;
  font-weight: 300 !important;
  opacity: 1;
}

.step-userinfo-input-icon {
  width: 12px;
}
.step-userinfo-select-container {
  position: relative;
}

.step-userinfo-select-container .step-userinfo-input-icon {
  position: absolute;
  top: 14px;
  left: 12px;
}

.step-userinfo-inputs .ant-select-search--inline .ant-select-search__field {
  font-size: 12px;
  margin-top: 2px;
  margin-left: 2px;
}

.step-userinfo-inputs .ant-calendar-picker-icon {
  right: unset;
  left: 12px;
}

.step-userinfo-inputs .ant-calendar-picker-input {
  padding-left: 28px;
}

.step-userinfo-input {
  margin-bottom: 0.8em;
}

.step-userinfo-input input {
  padding: 1.3em;
}

.step-userinfo-input input::placeholder,
.step-userinfo-input .ant-select-selection__placeholder,
.step-userinfo-intro textarea::placeholder {
  color: #595d6f;
  font-weight: bold;
}

.step-userinfo-input .ant-select-selection.ant-select-selection--single {
  height: 38px;
}

.step-userinfo-input .ant-select-selection__placeholder {
  margin-top: -7px;
}

.step-userinfo-input input,
.step-userinfo-input .ant-select-selection {
  border-color: #595d6f;
}

.step-userinfo-intro textarea.ant-input {
  border-color: #595d6f;
  padding: 1.3em;
  height: 100%;
}

.step-userinfo-intro .ant-form-item,
.step-userinfo-intro .ant-form-item-control-wrapper,
.step-userinfo-intro .ant-form-item-control {
  height: 100%;
}

.step-userinfo-oneliner {
  margin-top: 2em;
}

.step-userinfo-policy {
  text-align: center;
}

.steps-action {
  text-align: center;
  margin: 2em;
}

.steps-action button {
  font-weight: bold;
}

.steps-action > button.ant-btn-primary {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
}

.steps-action > button.ant-btn-primary:hover {
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}

.step-roles-roles {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 100px;
  max-width: 650px;
  margin: 0 auto 20px;
}

.step-roles-role {
  box-shadow: 0 0 19px -7px rgb(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.1s;
}

.step-roles-role:hover,
.step-roles-role.selected {
  background-color: var(--primary-blue);
  color: white;
  transition: all 0.1s;
}

.step-roles-role i {
  font-size: 2rem;
  margin-bottom: 7px;
}

.step-roles-role .step-roles-icon {
  width: 48px;
  height: 48px;
}

.step-roles-role:hover .step-roles-icon,
.step-roles-role.selected .step-roles-icon {
  filter: invert(1);
}

.step-interests-collapse {
  background-color: white;
}

.step-interests-panel {
  background-color: white;
  border-radius: 4px;
  margin-bottom: 20px;
  border-bottom: none !important;
  overflow: hidden;
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 0.9rem;
}

.step-interests-interests {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.step-interests-interest {
  padding: 0.5em 0.9em;
  border-radius: 5px;
  margin-bottom: 3px;
  border: none;
  background: #fff;
}

.step-interests-interest:hover,
.step-interests-interest.selected {
  background-color: var(--primary-blue);
  color: white;
}

.step-interests-panel .step-interests-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.step-interests-collapse-icon {
  color: var(--primary-blue);
}

.step-skills .skill-group-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

.step-skills .skill-group-buttons .group-button {
  margin: 15px 20px;
  padding: 12px 16px;
  font-weight: 900;
  border-radius: 9px;
  box-shadow: 10px 10px 20px #00000014;
  cursor: pointer;
}

.step-skills .skill-group-buttons .group-button.selected {
  background-color: var(--primary-blue);
  color: white;
}

.step-skills .skill-group-skills {
  font-weight: 900;
  max-width: 810px;
  margin: 0 auto;
}

.step-skills .skill-group-skills .skill {
  margin: 10px;
  width: 250px;
}
