@media (min-width: 500px) {
    .participants-btn-head {
        display: flex;
        flex-direction: row;
        gap: 0 8px;
    }
    .participants-btn-body {
        display: none;
    }
}

@media (max-width: 499px) {
    .participants-btn-head {
        display: none;
    }
    .participants-btn-body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px 10px;
        margin-top: 16px;
    }
}