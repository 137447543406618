.Notifications-uniqeScrollbar::-webkit-scrollbar {
    width: 2px;
}

.Notifications-uniqeScrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.Notifications-uniqeScrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

.Notifications-box {
    width: 400px;
    min-height: 200px;
    max-height: 70vh;
    overflow-y: auto;
    background: #fff;
    box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.04), 2px 4px 10px rgba(0, 0, 0, 0.04), 4px -8px 12px rgba(0, 0, 0, 0.03), 8px 16px 16px rgba(0, 0, 0, 0.03), 16px 32px 32px rgba(0, 0, 0, 0.02), 32px 64px 64px rgba(0, 0, 0, 0.02)
}

.Notifications-element {
    padding: 12px;
    border-bottom: 2px solid #f1f1f1;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #000
}

.Notifications-element-image {
    width:35px !important;
    height: 35px !important;
}
.Notifications-element-image img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
}

.Notifications-element-title {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding-left: 12px
}

.Notifications-element-messageBox {
    display: flex;
    justify-content: space-between;
    width: 340px;
    padding-left: 12px
}

.Notifications-element-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 230px;
    display: inline-block
}
.white-notification{
    filter: invert(1);
    /* width:24px; */
    height: 24px;
}
.side-notification{
    align-items: center;
    gap:10px;
    display: flex;
    flex-direction: row;
}
.sidebar-message > div{
    display: flex;
    align-items: center;
}