.project-container {
  max-width: 1300px;
  margin: 0 auto;
  justify-content: space-between;
}

.project-arrow-left {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-size: 32px;
  position: fixed;
  left: 250px;
  padding-top: 250px;
  cursor: pointer;
  height: 500px;
}
.project-arrow-right {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-size: 32px;
  position: fixed;
  right: 250px;
  padding-top: 250px;
  cursor: pointer;
  height: 500px;
}

@media screen and (max-width: 1500px) {
  .project-arrow-left {
    display: inline-block;
    width: 50px !important;
    text-align: center;
    font-size: 32px;
    position: fixed;
    left: 100px;
    padding-top: 130px;
    cursor: pointer;
    height: 500px;
  }
  .project-arrow-right {
    display: inline-block;
    width: 50px !important;
    text-align: center;
    font-size: 32px;
    position: fixed;
    right: 100px;
    padding-top: 130px;
    cursor: pointer;
    height: 500px;
  }
}
.project-arrow:hover {
  color: var(--primary-blue);
}

@media screen and (max-width: 1500px) {
  .project-arrow-left {
    display: inline-block;
    width: 50px !important;
    text-align: center;
    font-size: 32px;
    position: fixed;
    left: 100px;
    padding-top: 130px;
    cursor: pointer;
    height: 500px;
  }
  .project-arrow-right {
    display: inline-block;
    width: 50px !important;
    text-align: center;
    font-size: 32px;
    position: fixed;
    right: 100px;
    padding-top: 130px;
    cursor: pointer;
    height: 500px;
  }
}

@media screen and (max-width: 1300px) {
  .project-container {
    padding: 0;
  }

  .details .detail {
    width: 100% !important;
  }

  .logo-image-profile-container {
    left: calc(100vw - 279px) !important;
  }

  .project-arrow-left {
    display: inline-block;
    width: 50px !important;
    text-align: center;
    font-size: 32px;
    position: fixed;
    left: 0;
    padding-top: 130px;
    cursor: pointer;
    height: 500px;
  }
  .project-arrow-right {
    display: inline-block;
    width: 50px !important;
    text-align: center;
    font-size: 32px;
    position: fixed;
    right: 0;
    padding-top: 130px;
    cursor: pointer;
    height: 500px;
  }
}

.project-cover-title-mobile {
  display: none;
}

.project-cover-subtitle-mobile {
  display: none;
}

.Project {
  border-radius: 24px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  margin-top: 6em;
  margin-bottom: 3em;
  color: #262626;
}

.project-cover {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 38px 67px;
  height: 264px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.project-cover-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  z-index: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.project-cover-title-container,
.project-cover-image-upload {
  z-index: 2;
}
.project-cover-image-upload {
  display: flex;
}

.project-cover-title-container {
  flex: 1;
}

.project-cover-title-container input {
  background: rgba(255, 255, 255, 0.8);
}

.project-cover-title {
  color: white;
  font-size: 36px;
  font-weight: 900;
}

.project-cover-subtitle {
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.logo-image-profile-container {
  position: absolute;
  overflow: hidden;
  width: 163px;
  height: 163px;
  border-radius: 50%;
  border: 12px solid white;
  background: white;
  margin-top: -120px;
  z-index: 1;
  left: calc(50vw + 370px);
}

.logo-image-logo {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.project-body {
  padding: 0 56px 56px;
}

.project-body-cards {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: auto;
  gap: 23px;
  margin-top: -40px;
  position: relative;
}

.project-body-cards .card {
  padding: 24px 24px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  background-color: white;
  margin-bottom: 0;
}

.project-body-cards .card-title {
  font-size: 21px;
  font-weight: 900;
  margin-bottom: 10px;
}

.project-body-cards .card-subtitle {
  font-size: 15px;
  font-weight: 300;
}

.card.highlights {
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  align-items: flex-start;
}

.card.details {
  grid-column: 2 / 3;
  padding-left: 28px;
  padding-right: 28px;
  overflow: visible;
  word-wrap: break-word;
}

.awards-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}
.card-awards{
	display: flex;
  flex-wrap: wrap;
  gap: 35px;
}
.awards {
  grid-column: 1 / 3;
  width: 100%;
}
.award {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  max-height: 250px;
  min-width: 200px;
}
.award-icon-name-holder{
  display: flex;
  flex-direction: row;
}
.award-icon {
  width: 34px;
  height: 34px;
  margin-right: 15px;
  margin-top: 5px;
}

.award-title {
  font-size: 11px;
  font-weight: 900;
  margin-bottom: 10px;
  height: 18px;
  margin-left: 50px;
}
.award-name {
  height: 48px;
  max-width: 150px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  color: rgb(189, 158, 117);
  /* margin-bottom: 20px; */
}
.award-description {
  font-size: 12px;
  margin-left: 50px;
  margin-top: 20px
}
.highlights .highlight {
  display: flex;
}

.highlights .highlight img {
  width: 20px;
  height: 20px;
  margin-right: 7.5px;
}

.interests-icons > span {
  display: flex;
}

.highlight-text .highlight-text-title {
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
}

.highlight-text {
  width: 80%;
}

.highlight-text .ant-input-number {
  width: 100%;
}

.highlight-text .ant-input-number,
.highlight-text .ant-select-selection {
  border-color: black;
}

.detail .ant-form-item,
.highlight-text .ant-form-item,
.new-member .ant-form-item,
.financial-table .ant-form-item {
  /* margin-bottom: 0; */
}

.new-member {
  margin-left: 24px;
}

.highlight-text .highlight-text-desc {
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.details .details-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.details-inner {
  /* margin-bottom: 20px; */
  display: flex;
  flex-wrap: wrap;
}

.details-inner-whatweneed {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  white-space: break-spaces;
}
.details .detail {
  width: 340px;
}

.details .detail .detail-title {
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
}

.details .detail .detail-desc {
  font-size: 14px;
  line-height: 26px;
  white-space: break-spaces;
}

#category-editmode::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.financial-table .ant-input-number-handler-wrap {
  display: none;
}

.financial-table .row {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  padding-right: 15px;
}

.financial-table .row.header {
  justify-content: space-between;
  font-size: 15px;
  font-weight: 900;
  display: flex;
  align-items: center;
}
.header-editmode {
  display: flex;
  margin: -4px;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 10px;
  padding-right: 52px;
  gap: 20px;
  margin-left: 124px;
}
.year-input {
  margin-right: 10px;
}

.financial-table .row.header span {
  width: 20%;
  text-align: start;
}

.financial-table .row:not(.header) {
  border-radius: 9px;
}

.financial-table .row:not(.header) span {
  width: 25%;
  font-size: 12px;
  font-weight: 300;
}

.finance-item {
  padding: 16px 0;
  border-bottom: 1px solid #e1e1e1;
}

.financial-table .row span.title {
  font-weight: 900;
  font-size: 15px;
  text-align: left;
  padding: 16px 0;
}

.interests-display span {
  display: block;
  margin: 20px 24px;
}

.interests-display .interests-icons img {
  max-width: 28px;
  max-height: 28px;
  margin-right: 5px;
  margin-top: -2px;
}

.interests-icons {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.interests-display .card-title {
  margin-bottom: 0;
  margin-right: 15px;
}

.interests label[for="ideas_interests"] {
  font-weight: 900;
  font-size: 20px;
}

.project-body-checkboxes .ant-checkbox + span {
  font-weight: 900;
  font-size: 17px;
}

.project-buttons-container {
  margin-bottom: 6em;
  text-align: center;
}

.project-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.project-buttons-draft {
  display: flex;
  justify-content: space-between;
}
.card-team-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.join-button {
  margin-top: 4px;
  height: 25px;
}
.team-container {
  /* position: fixed; */
  position: relative;
}
.project-team-container {
  grid-column: 1/3;
}
.join-button-container {
  /* position: relative; */
  position: absolute;
  margin-top: 95px;
  margin-left: 333px;
  z-index: 2;
}
.link-input {
  width: 250px;
  height: 35px;
}

.project-buttons button {
  font-weight: bold;
}

.project-button {
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.project-actions {
  display: flex;
  box-shadow: 10px 10px 20px #00000014;
  border-radius: 16px;
  overflow: hidden;
}

.project-actions .Idea-action {
  font-size: 20px;
}

.project-social {
  border-radius: 24px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
  padding: 3em 6em;
  margin-bottom: 6em;
}

.project-social-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 1em;
  font-size: 16px;
  padding: 0 1em;
}

.project-social-likes i {
  margin-right: 5px;
}

.project-social-comments {
  position: relative;
  margin-right: 35px;
}

.EventTag {
  display: inline-block;
  background: var(--primary-blue);
  padding: 2px 4px;
  color: #fff;
  margin-right: 8px;
  margin-bottom: 8px;
}

.Project .ant-select-search--inline .ant-select-search__field {
  font-size: 12px;
  margin-left: 4px;
  margin-top: -2px;
}

.details-mobile {
  width: 161px;
  height: 161px;
}

.logo-image-profile-container-mobile {
  overflow: hidden;
  width: 161px;
  height: 161px;
  border-radius: 50%;
  border: 10px solid #fff;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-image-profile-container-mobile img {
  width: 100%;
  object-fit: cover;
}
.details-mobile {
  display: none;
}
@media screen and (max-width: 512px) {
  .project-body-cards .card {
    padding: 16px 16px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    background-color: white;
    margin-bottom: 0;
  }
  .project-cover {
    padding: 20px 40px;
  }
  .project-cover-title {
    color: white;
    font-size: 24px;
    font-weight: 900;
  }
  .project-cover-subtitle {
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
  div.highlight:nth-child(9) > img:nth-child(1) {
    width: 32px;
    height: 32px;
    margin-right: 15px;
    margin-top: 6px;
  }

  .logo-image-profile-container {
    width: 82px;
    height: 82px;
    border: 4px solid white;
    margin-top: -60px;
    left: calc(50vw - 40px) !important;
  }
  .project-body {
    padding: 0 16px 16px;
  }
  .project-body-cards {
    margin-top: -20px;
    gap: 8px;
  }
  .first-title {
    margin-top: 35px;
    margin-bottom: 4px;
    width: 100%;
  }

  .project-body-cards .card-title {
    width: 100%;
    text-align: start;
  }
  .project-body-checkboxes {
    display: unset;
  }
  .card-title-editmode,
  .card-subtitle,
  .card-title-editmode-financial,
  .card-subtitle-editmode-financial {
    width: 100%;
    text-align: start;
  }

  .card.highlights {
    grid-gap: 16px;
    gap: 16px;
  }
  .details .details-inner {
    grid-gap: 16px;
    gap: 16px;
  }
  .interests-icons {
    margin-top: 25px;
    grid-gap: 16px;
    gap: 16px;
  }
  div.card:nth-child(2) > h3:nth-child(1) {
    margin-bottom: 20px;
  }
  .financial-table {
    margin-top: 0;
  }

  .financial-table-container {
    overflow: auto;
    padding: 5px;
    margin-left: 0;
  }
  .project-social {
    border-radius: 24px;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
    padding: 16px 16px;
    margin-bottom: 6em;
  }
  .project-social-comments {
    margin-left: unset;
    margin-right: 0 !important;
  }

  .project-social-info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0;
    font-size: 10px;
    padding: 0;
  }
  .CommentBlockElement > .message {
    margin-left: 10px;
    width: 66%;
}
}

@media (min-width: 513px) and (max-width: 960px) {
  .Project {
    margin-top: 1em;
  }

  .card-title.first-title {
    margin-top: 60px;
  }

  .card-title,
  .card-subtitle {
    width: 100%;
  }

  .logo-image-profile-container {
    left: calc(50vw - 81px) !important;
  }

  .highlights .highlight {
    width: 220px;
    flex-grow: 1;
  }

  .card.highlights {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }

  .details-mobile {
    margin: 0 auto;
    display: block;
  }

  .project-body-cards .card-subtitle {
    margin-top: 0 !important;
  }

  .project-cover {
    padding: 30px 34px;
    height: 300px;
  }

  .project-body-cards {
    grid-template-columns: 1fr 1fr;
  }

  .project-body-cards .card {
    grid-column: 1 / 3;
  }

  .card.highlights {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  .financial-item-year {
    /* margin-right: 20px; */
    float: left;
  }
  .finance-item {
    float: left;
  }

  .card.details {
    grid-column: 1 / 3;
  }

  .card.interests {
    grid-column: 1 / 3;
  }

  .financial-table-container {
    overflow: auto;
    padding: 5px;
    margin-left: 24px;
  }

  .financial-table {
    min-width: 550px;
  }

  .interests-display {
    display: unset;
  }

  .project-body-cards .Idea-interest-icon {
    margin-top: 15px;
  }

  .project-social {
    padding: 10px;
  }

}
