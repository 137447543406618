.Content-container {
    padding-bottom: 64px;
}

.Groups-header {
    padding-top: 80px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.Groups-header h1 {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2em
}

.Groups-header p {
    font-size: 20px;
    margin-top: 3em;
    line-height: 1.4em;
    font-weight: 300;
    max-width: 514px
}

.GroupPage-groupList {
    margin-top: 40px
}

.GroupPage-groupList h1 {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2em
}

.Groups-typefilter .GroupType {
    border-radius: 9px;
    box-sizing: content-box;
    cursor: pointer;
    display: inline-flex;
    width: 148px;
    height: 82px;
    padding: 12px 8px 4px;
    margin: 6px;
    text-align: center;
    box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.04),
        2px 4px 10px rgba(0, 0, 0, 0.04),
        4px -8px 12px rgba(0, 0, 0, 0.03),
        8px 16px 16px rgba(0, 0, 0, 0.03),
        16px 32px 32px rgba(0, 0, 0, 0.02),
        32px 64px 64px rgba(0, 0, 0, 0.02)
}

@media (max-width: 600px) {
    .Groups-typefilter .GroupType {
        width: 133px;
        height: 97px;
    }
}

.Groups-typefilter {
    margin-bottom: 20px;
    display: inline-block;
    max-width: 720px;
}

@media (max-width: 969px) {
    .Groups-typefilter {
        max-width: 100%;
        width: 100%;
    }

    .Groups-header {
        display: block;
    }
}