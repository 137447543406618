.elem-holder {
  margin-right: 24.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.elem-img-holder {
  width: 20px;
  height: 30px;
  align-items: start;
  margin-top: 4px;
}
.elem-name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
  color: #333333;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8.8px;
}
.elem-img-holder img {
  max-height: 20px;
  max-width: 20px;
  width: 20px;
}
