/* CommentHead2 */
.CommentHead2 {
    display: flex;
    align-items: center;
    margin-bottom: 1.1em;
}
.Comment-action {
	flex: 1;
	text-align: center;
	padding: .5em;
	cursor: pointer;
}
.CommentHead2 > .comment-profile-img {
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    border-radius: 50%;
    background-position: center;
}

.CommentHead2 > .message {
    margin-left: 10px;
    width: 90%;
}

.CommentHead2 > .message > p {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 1em;
    padding: .6em;
    margin-bottom: 0;
    line-height: 1.3em;
    word-break: break-word;
}

.CommentHead2 > .message > p > .message-input {
    border: none
}

.CommentHead2 > .message > p > .message-input:active,
.CommentHead2 > .message > p > .message-input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

/* CommentBlockElement */
.CommentBlockElement {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.1em;
}

.CommentBlockElement > .comment-profile-img {
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    border-radius: 50%;
    background-position: center;
}

.CommentBlockElement .message-sender {
    color: unset;
}

.CommentBlockElement > .message {
    margin-left: 10px;
    width: 81%;
}

.CommentBlockElement > .message > p {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 1em;
    padding: .6em;
    margin-bottom: 0;
    line-height: 1.3em;
    word-break: break-word;
}

.CommentBlockElement > .message > p .input {
    border: none;
}

.CommentBlockElement > .message > p .input:active,
.CommentBlockElement > .message > p .input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.CommentBlockElement > .message > p span {
    font-weight: bold;
    margin-right: 5px;
    word-break: break-all;
}

.CommentBlockElement > .message > .date {
    font-size: .8rem;
    padding-left: .5em;
}

/* CommentBlock */
.CommentBlock {

}
