.ant-calendar-picker-input {
    border:1px solid black;
    color: #6755CE;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
}

.ant-time-picker-input {
    border: 1px solid #B8B8B8;
    color: #6755CE;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
}
