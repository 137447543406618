.testimonial-card{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: fit-content;
    z-index: 1;
}

.testimonial-image{
    height: 64px;
    width: 64px;
    margin-right: 12px;
}
.testimonial-name{
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    color: #262626;
}
.testimonial-position{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #555555;
}
.testimonial-description{
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #262626;
    margin-top: 15px;
}

.testimonial-title-part{
    display: flex;
    align-items: center;
}

.testimonial-navigation{
    margin-top: 40px;
}