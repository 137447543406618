.embed-summary{
  display: inline-block;
  float: right;
  margin-left: 20px;
  margin-top: -40px;
}
.embed-video iframe{
  width: 450px;
  height: 300px;
}

@media (max-width: 1150px) {
  .embed-summary{
      display: inline-block;
      /* margin-top: -145px; */
  }
  
  .embed-summary iframe {
    position: relative;
    top: 0;
    
  }

}

@media (max-width: 1050px) {
  .embed-summary{
      display: block;
  }
}



@media (max-width: 1170px) {
    .embed-summary{
        display: inline-block;
    }
    
    .embed-summary iframe {
      position: relative;
      top: 0;
      
    }

}
@media (max-width: 1130px) {
  .embed-summary{
      margin-top:-120px
  }
  
  .embed-summary iframe {
    position: relative;
    top: 0;
    
  }

}

@media (max-width: 1010px) {
  .embed-summary{
    margin-top: 0;
      display: block;
      width:100%
  }
}
@media (max-width: 1010px) {
  .embed-summary{
   margin-left: 5px;
  }
  .embed-video iframe{
    width: 300px;
    height: 200px;
  }
}