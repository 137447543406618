.article-about-description {
    padding: 24px;
    overflow-wrap: break-word;
    word-break: break-word;
}

.display-member-article>div>div> :first-child {
    width: 77px;
    height: 77px;
}

.display-member-article>div>div> :last-child {
    align-self: center;
}

.display-member-article>div>div> :last-child>div {
    font-size: 18px;
}

.written-by {
    padding: 24px;
    overflow-wrap: break-word;
    word-break: break-word;
}

.article-desc-container {
    flex-wrap: wrap;
    grid-gap: 24px;
    gap: 24px;
    margin-left: 40px;
    margin-right: 40px;
}

.article-about-others {
    margin-bottom: 3em;
    overflow-wrap: break-word;
    word-break: break-word;

}

.article-about-others>div {
    width: fit-content;
}

.article-outer-box {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    margin-bottom: 3em;
}
.other-articles-title{
    font-size: 32px;
}

.other-articles .card .content .social .socialTextBoxmodified .socialText{
    word-break: normal;
    text-align: center;
    width: 25%;
    margin-right: 0;
}
.other-articles-button{
    justify-content: center;
    display: flex;
}
.other-articles{
    gap:20px;
}

.organization-about-description-desc > p > img{
    max-width: 100%;
}
.organizationpage-title-card-subtitle {
    font-family: Inter;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
  }

  .ql-editor{
      min-height: 21rem;
  }