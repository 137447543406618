.ownerBox {
  /* margin-bottom: 32px; */
}
.userBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 20px; */
  /* margin-bottom: 24px; */
}
.ownerBox h3 {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
}
.userName {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #333333;
}
.userRol {
  margin-top: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
}
.owner-profile-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  border-radius: 50%;
  background-position: center;
  
}
