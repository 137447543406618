.forgotten_pw {
  display: flex;
  justify-content: center;
  height: 60vh;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
.forgotten_pw_holder {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 24px rgba(30, 13, 98, 0.15);
  border-radius: 16px;
  min-height: 300px;
}
.forgotten_pw_header {
  width: 464px;
  height: 64px;
  background: #6755ce;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: 40px;
}
.forgotten_pw_header h1 {
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 16px 40px;
  position: absolute;
}
.content_holder {
  margin-left: 40px;
}
.input input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;
  position: static;
  width: 384px;
  height: 40px;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  border-radius: 3px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px 0px 0px;
}
.input::placeholder {
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.input svg {
  margin-top: 8px;
}
.login_btn {
  width: 144px;
  height: 40px;
  margin-top: 12px;
  margin-right: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
