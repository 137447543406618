.organization-title-container {
  box-shadow: rgb(0 0 0 / 8%) 10px 10px 24px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 40px;
  padding-left: 40px;
  max-width: 64%;
  margin-top: -71px;
  position: relative;
}

.organization-title-container h1 {
  font-weight: 900;
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 13px;
}

.organization-input-container {
  width: 60%;
}

.organization-input-container h2 {
  margin-top: 32px;
  margin-bottom: 10px;
  font-family: Inter;
  font-weight: 900;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: rgb(40, 40, 40);
  display: inline-block !important;
  margin-right: 8px;
}

.organization-form-container {
  margin-left: 47px;
}

.organization-body {
  max-width: 1280px;
  margin: 0 auto;
}

.organization-unique-tag > div > span > span {
  width: 192px !important;
  font-size: 12px;
}

.organization-unique-tag > div > span > input {
  padding-left: 195px !important;
  padding-bottom: 6px;
}

.organization-social-media > div div {
  width: 48%;
}

.organization-location > div {
  max-width: unset;
}

.organization-location {
  max-width: 282px;
}

.organizer-logo {
  width: 203px !important;
  height: 203px !important;
}

.organizer-logo
  > .ImageUploader
  > .clearfix
  > .ant-upload-picture-card-wrapper
  > .ant-upload-select-picture-card {
  width: 180px;
  height: 180px;
}

.organization-interests {
  width: 100%;
}

.organization-interests h2 {
  display: block;
}

.organizer-logo
  > .ImageUploader
  > .clearfix
  > .ant-upload-picture-card-wrapper
  > .ant-upload-list-picture-card
  > .ant-upload-list-picture-card-container
  > span
  > .ant-upload-list-item {
  width: 180px;
  height: 180px;
}

.organizer-logo
  > .ImageUploader
  > .clearfix
  > .ant-upload-picture-card-wrapper
  > .ant-upload-list-picture-card
  > .ant-upload-list-picture-card-container {
  width: 180px;
  height: 180px;
}

.organization-interest-modal {
  width: 90% !important;
}

.error-response-organization {
  text-align: center;
  margin-bottom: 20px;
}

.organization-description-preview {
  border: 1px solid rgb(38, 38, 38);
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  min-height: 400px;
}

.organization-form-container h1 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
  margin-top: 32px;
}

.organization-input-container ul {
  margin-top: 8px;
}

.organization-doublebox {
  width: 100%;
  display: flex;
}

.organization-delete-button {
  font-size: 16px;
  font-weight: 900;
  color: #df4e4e;
  text-align: center;
  margin-bottom: 32px;
  margin-left: 40px;
}

.organization-delete-button:hover {
  cursor: pointer;
}

.organization-partner-container > div {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 24px;
}

 .organization-input-container >div > div > span > div {
  width: 100%;
}

  .organization-input-container
  > div
  > div
  > span
  > div
  > span
  > div {
  max-width: 100% !important;
}

 .organization-partner-container > div > div > div {
  width: 140px !important;
  height: 49px !important;
}

.organization-add-team>div{
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 24px;
}

.organization-interests> .select-items-container > .main-category{
  width:100%;
}

.organization-unique-tag-prefix{
  padding-left: 5px;
}
.organization-unique-tag > .errors-frombe-onepager {
margin-top: -24px;
text-align: start;  }