.card-details {
  display: flex;
  flex-direction: column;
}
.details-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
}

.ant-upload.ant-upload-select-picture-card {
  width: 139px;
  height: 139px;
  margin: 0;
  border-radius: 50%;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 139px;
  height: 139px;
}
.ant-upload-list-picture-card-container {
  width: 139px;
  height: 139px;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  opacity: 1;
}
.logo-uploader {
  display: flex;
  float: right;
  align-content: center;
  justify-content: center;
  position: absolute;
  z-index: 15;
  margin-top: -120px;
  z-index: 9;
  width: 163px;
  height: 163px;
  border-radius: 50%;
  border: 12px solid white;
  background: white;
  left: calc(50vw + 370px);
}
.item-title {
  font-weight: 900;
  font-size: 14px;
}
.inputs-field {
  display: flex;
  flex-direction: row;
  height: 70px;
}
.details-img {
  width: 36px;
  height: 36px;
  margin-right: 25px;
  margin-top: -15px;
}
.project-body-cards-edit {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: auto;
  gap: 23px;
  margin-top: -294px;
  position: relative;
}

.project-body-cards-edit .card {
  padding: 24px 24px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  background-color: white;
  margin-bottom: 0;
}

.area-subscription-required {
  font-size: 12px;
  line-height: 20px;
}
.area-subscription {
  margin-top: -30px;
  font-size: 12px;
  line-height: 20px;
}

.card-title-editmode-financial {
  font-size: 21px;
  font-weight: 900;
  margin-bottom: 1.5em;
}

.card-subtitle-editmode-financial {
  font-size: 15px;
  font-weight: 300;
  margin-top: -1.5em;
  margin-bottom: 1.5em;
}
.card-subtitle-editmode-category {
  font-size: 15px;
  font-weight: 300;
  margin-top: -1.5em;
  margin-bottom: 1.5em;
}

.details-item {
  width: 340px;
  flex-grow: 1;
}
.card-title-editmode {
  font-size: 21px;
  font-weight: 900;
  margin-bottom: 1.5em;
}
.finance-row {
  display: flex;
}

.header-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 110px;
  margin-right: 45px;
}
.commentable-box {
  font-size: 15px;
  font-weight: 900;
}

.financial-row-edit {
  padding-top: 28px;
  border-bottom: 1px solid #e1e1e1;
}

.financial-row-edit div span {
  width: 100px !important;
}

.financial-row-edit div input {
  width: 100px !important;
}

.ant-input-prefix {
  width: 10px !important;
}

.title-inputs-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -230px;
  z-index: 10;
}
.select-box {
  width: 100%;
  height: 32px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #262626;
}
@supports (-moz-appearance: none) {
  .select-box {
    background-color: white;
    font-size: 12px;
    border: 1px solid #262626;
    opacity: 1;
  }
}

.title-inputs div input {
  height: 32px;
}

.pac-target-input {
  width: 78%;
  height: 32px;
}
.item-title-highlight {
  margin-left: 60px;
  font-weight: 900;
  font-size: 14px;
}
.financial-table .row span.title {
  align-self: center;
}
.title-inputs {
  display: flex;
  width: 340px;
  height: 80px;
  flex-flow: wrap;
  align-content: space-between;
}
.details-left {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
.details-right {
  width: 250px;
}
.checkbox-deletebutton {
  display: flex;
  flex-direction: row;
}
.Project-checkboxes {
  grid-column: 1/3;
}
.ImageUploader {
  width: 20%;
  height: 20%;
}
.financial-row {
  align-self: center;
}
.select-box .interest-select {
  width: 80%;
}
.launch-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 40px;
}
.launch-btn {
  margin-left: 35px;
  font-weight: 900;
}
.team-member-holder {
  grid-column: 1/3;
  padding: 24px;
}
.error-onepager {
  font-size: 12px;
  font-family: "Inter";
  font-style: "normal";
  font-weight: 300;
  color: rgb(245, 34, 45);
}

.errors-frombe-onepager {
  margin-top: 24px;
  text-align: center;
  color: rgb(245, 34, 45);
  white-space: break-spaces;
}
.powered-by-google-onepager-edit {
  max-height: 12px;
}

.item-title-highlight-location {
  margin-left: 60px;
  font-weight: 900;
  font-size: 14px;
  display: inline-block;
  margin-right: 15px;
}

#category-editmode::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.required-edit::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.edit-onepager-delete {
  position: absolute;
  right: 0;
}

.card.highlights.edit {
  gap: 0;
  grid-gap: 0;
}

.edit-highlight-item {
  width: 100%;
}

.location-selector {
  width: 100%;
  max-width: 280px;
}

.error-container-onepager-location {
  margin-left: 60px;
  margin-top: -35px;
  font-size: 12px;
  font-family: "Inter";
  font-style: "normal";
  font-weight: 300;
  color: "#F5222D";
}
@media screen and (max-width: 1300px) {
  .project-container {
    padding: 0;
  }

  .details .detail {
    width: 100% !important;
  }

  .logo-uploader {
    left: calc(100vw - 279px) !important;
  }
}

@media screen and (max-width: 960px) {
  .Project {
    margin-top: 1em;
  }

  .title-inputs-logo {
    margin-top: -265px;
  }

  .edit-project-body {
    margin-top: -340px;
  }

  .edit-highlight-item {
    width: 250px;
    flex-grow: 1;
  }

  .card.highlights.edit {
    justify-content: space-between;
    column-gap: 24px;
  }

  .first-title {
    margin-top: 60px;
    width: 100%;
  }

  .card-title-editmode,
  .card-subtitle,
  .card-title-editmode-financial,
  .card-subtitle-editmode-financial {
    width: 100%;
  }

  .logo-uploader {
    left: calc(50vw - 81px) !important;
  }

  .highlights .highlight {
    width: 220px;
    flex-grow: 1;
  }

  .card.highlights {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }

  .details-item {
    width: 100%;
  }

  .details-container {
    gap: 10px;
  }

  .project-body-cards .card-subtitle {
    margin-top: 0 !important;
  }

  .project-body-cards {
    grid-template-columns: 1fr 1fr;
  }

  .project-body-cards .card {
    grid-column: 1 / 3;
  }

  .card.highlights {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  .financial-item-year {
    /* margin-right: 20px; */
    float: left;
  }
  .finance-item {
    float: left;
  }

  .card.details {
    grid-column: 1 / 3;
  }

  .card.interests {
    grid-column: 1 / 3;
  }

  .financial-table-container {
    overflow: auto;
    padding: 5px;
    margin-left: 24px;
  }

  .financial-table {
    min-width: 550px;
  }

  .interests {
    overflow: visible;
  }

  .financial {
    grid-column: 1 / 3;
  }

  .member-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 320px) and (max-width: 512px) {
  .first-title {
    margin-top: 30px;
    width: 100%;
  }
  .card-title-editmode,
  .card-subtitle,
  .card-title-editmode-financial,
  .card-subtitle-editmode-financial {
    width: 100%;
    text-align: start;
  }
  .financial-table-container {
    overflow: auto;
    padding: 5px;
    margin-left: 0;
  }
}
