.deadline-location {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.deadline {
  display: flex;
  flex-wrap: wrap;
  margin-right: 55px;
}
.deadline-icon-text {
  color: rgb(40, 40, 40);
  font-family: Inter;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
}
.icon {
  margin-right: 10px;
  color: rgb(103, 85, 206);
}
.deadline-date {
  color: rgb(103, 85, 206);
  font-family: Inter;
  font-weight: 900;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  margin-left: 10px;
}
.location {
  color: rgb(103, 85, 206);
  font-family: Inter;
  font-weight: 900;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
}
