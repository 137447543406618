.wrapper {
    display: flex;
    gap: 26px;
    background-color: #FBF7FF;
    padding: 1%;
  }
.dashboard-left-side{
    background-color: #FBF7FF;
    display: flex;
    gap: 26px;
    justify-self: flex-start;
    flex-direction: column;
    width: 30%;
    min-width: 305px;
  }
.dashboard-mid-side{
    background-color: #FBF7FF;
    display: flex;
    gap: 26px;
    justify-self: flex-start;
    flex-direction: column;
    width: 42%;
    margin-bottom: 35px;
}
.dashboard-right-side{
    background-color: #FBF7FF;
    display: flex;
    gap: 26px;
    justify-self: flex-start;
    flex-direction: column;
    width: 25%;
}
.feedlist{
    background: #FFFFFF;
    border-radius: 16px;
    padding: 20px;
    max-height: 1700px;
    height: 1700px;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
      
  }

.feedlist::-webkit-scrollbar {
    display: none;
  }
.progress-bar-title{
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #262626;
}
.progress-bar-percantage{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.progress-percentage{
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    color: #6755CE;
    word-break: initial;
    margin-right: 50px;
}
.completed-text{
   margin: 0;
}
.create-post-container{
  width: 100%;
  margin-left: 16px;
}
.create-post{
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.create-post-textarea{
  max-height: 300px !important;
}
.discover-events-card{
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border: solid 1px #E1E1E1;
  border-radius: 10px;
  width: 100%;
  min-height: 132px;
}
.discover-events-image{
  height: 100%;
  border-radius: 8px 0px 0px 8px;
  width: 133px;
  object-fit: cover;
}
.newuser-name{
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
}
.dashboard-card-title{
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  margin-bottom: 12px;
}
.upcoming-container{
  display: flex;
  flex-direction: column;
  border: solid 1px #E1E1E1;
  border-radius: 10px;
  cursor: pointer;
}
.upcoming-cover{
  height: 169px;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  display: block;
  object-fit: cover;

}
.upcoming-location{
  display: flex;
  flex-direction: row;
}
.discover-card{
  background: #FFFFFF;
  padding:20px;
  border-radius: 16px
}
.upcoming-card{
  background: #FFFFFF;
  padding:20px;
  border-radius: 16px
}
.newusers-card{
  background: #FFFFFF;
  padding:20px;
  border-radius: 16px
}
.latest-startups-card{
  background: #FFFFFF;
  padding:20px;
  border-radius: 16px
}
.startup-name{
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #262626;
}
.Profile-progress{
  background: #FFFFFF;
  border-radius: 16px;
  padding:20px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
}
.card-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.seeall{
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  color: #6755CE;
  cursor: pointer;
}
.discover-content-container{
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
}
.discover-info{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.discover-title{
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  /* align-items: center; */
  color: #262626;
}
.discover-location{
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #555555;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 6px;
}
.discover-deadline{
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #555555;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 6px;
}
.attend{
  margin-left:80%;
  display: flex;
  flex-direction: row;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #6755CE;
  cursor: pointer;
  justify-content: flex-end;
}
.attend div {
    word-break: initial;
}
.location-duration{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #6755CE;
}
.location-duration-date{
    margin-right: 30px;
}
.upcoming-title{
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #262626;
}
.upcoming-content{
  padding:16px;
}
.user-profile-pic{
  width:50px;
  height: 48px;
  border-radius:50%;
}
.send-post-area{
  width:100%;
  height:40px
}
.share-post{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  cursor: pointer;
}
.milestones-card{
  background: #FFFFFF;
  padding:20px;
  border-radius: 16px;
}
.milestone-first-startup{
  border: solid 1px #E1E1E1;
  border-radius: 10px;
  padding:16px;
}
.milestone-first-idea{
  border: solid 1px #E1E1E1;
  border-radius: 10px;
  padding:16px;
  margin-top: 16px;
}
.milestone-text{
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #262626;
}
.milestone-text img{
  margin-right: 10px;
}
.milestone-getstarted{
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #6755CE;
  cursor: pointer;
  margin-top: 15px;
}
.idea-default-logo{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}
.create-post-button{
  margin-top: 16px;
  float: right;
}
.progress-complite-now{
  float: right;
}
.progress-edit-holder{
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.progress-edit-img{
  margin-right: 5px;
}
.progress-edit-text{
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  color: #6755CE;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 6px;
}
.progress-bar .ant-progress-bg{
  height: 4px !important;
}
.latest-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.role-item{
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #555555;
}
.handle-image{
  display: flex;
  flex-direction: row;
  gap:15px;
}

.latest-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.post-image-preview-container{
  width: 100%;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 10%) 3.38462px 3.38462px 13.5385px;
  overflow: hidden;
  margin-top: 16px;
  text-align: center;
}
.post-image-preview{
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.delete-image{
  width: 20px;
  height: 20px;
}
.post-image-picker{
  display: flex;
  flex-direction: row;
  gap:8px;
}
.image-picker-text{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #262626;
}
.view-text{
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #6755CE;
  word-break: initial;
}
.view-text-box{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}
.latest-roles{
  display: flex;
  flex-direction: row;
}
.dashboard-img{
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.latest-icon-name-interes{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.new-user-name-rol{
  display: flex;
  flex-direction: column;
}
.new-user-rol{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #555555;
}
.new-user-content-holder{
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.dashboard-information-group{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard-information-item{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #262626 !important;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;
}
.dashboard-information-column{
  display: flex;
  margin-bottom: 12px;
}

@media screen and (min-width: 1445px) {
  .wrapper {
    justify-content: center;
  }
  .dashboard-right-side{
    max-width: 400px;
  }
  .dashboard-mid-side{
    max-width: 700px;
  }
  .dashboard-left-side{
    max-width: 480px;
  }
  
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .wrapper{
    padding: 1%;
    gap: 13px;
  }
  .discover-events-card{
    flex-direction: column;
  }
  .discover-events-image{
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: 100px;
  }
}

@media (max-width:1023px){
  .wrapper{
    justify-content: center;
  }
  .dashboard-left-side{
    display: none;
  }
  .dashboard-right-side{
    display: none;
  }
  .dashboard-mid-side{
    width: 60%;
  }
}
@media (max-width:550px){
  .wrapper{
    padding: 0;
  }
  .dashboard-mid-side{
    width: 95%;
  }
 
}