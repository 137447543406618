@media (min-width: 790px) {
    .evaluation-table-mobile {
        display: none;
    }
}

@media (max-width: 789px) {
    .evaluation-table-desktop {
        display: none;
    }
}

@media (min-width: 430px) {
    .evaluation-mobile-avg-body {
        display: none;
    }
}

@media (max-width: 429px) {
    .evaluation-mobile-avg-head {
        display: none;
    }
}
