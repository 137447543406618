.Profile-container{
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 0.9em;
}
.ProfilePage {
  border-radius: 20px;
  box-shadow: 0 0 19px -7px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 6em 0;
}

.ProfilePage-cover {
  height: 331px;
  width: 100%;
  background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    background-position: center;
}

.ProfilePage-body {
  padding: 2em 40px;
  padding-top: 0;
}

.ProfilePage-header {
  display: flex; 
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: -90px;
  flex: 30%;
}
.profile-progress .ant-progress-bg {
  height: 20px !important;
}
.ProfilePage-image {
  position: relative;
}
.financial-item {
  float: left;
}
.financial-item-edit {
  text-align: center;
}

.ProfilePage-image-profile-container {
  width: 250px;
  height:  250px;
  border-radius: 50%;
  border: 10px solid white;
  background: white;
}

.ProfilePage-image-profile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ProfilePage-image-role {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: 100px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.078);
}

.ProfilePage-image-role img {
  width: 60%;
}

.ProfilePage-title {
  margin-bottom: 8px;
  margin-left: 1em;
  /*! margin-right: 20px; */
  /*! max-width: 500px; */
  width: 72%;
}
.ProfilePage-title > div:nth-child(2) {
  max-width: 80%;
 }
 
.Profile-card-holder{
  display: flex;
  gap: 25px;
  width: 100%;
}
.social-links-profile-mobile{
  display: none;
}
.ProfilePage-title-greeting i {
  cursor: pointer;
  font-size: 16px;
  margin-left: 24px;
  vertical-align: baseline;
}

.ProfilePage-title-details {
  display: flex;
  margin-top: 20px;
}

.ProfilePage-title-details .detail {
  margin-right: 1.2em;
  font-size: 12px;
  font-weight: 900;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}

.ProfilePage-title-details .detail img {
  width: 15px;
  margin-right: 7px;
}

.ProfilePage-cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4em;
}

.ProfilePage-card {
  border-radius: 20px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.078);
  padding: 1.5em;
  margin-bottom: 40px;
}

.ProfilePage-card .card-title {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.03em;
  margin-bottom: 1em;
}

.about-oneLiner img {
  width: 20px;
  margin-right: 15px;
}
.card-interests {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
 }
 .ProfilePage-card .interest {
  margin-bottom: 1em;
  display: flex;
}
/* .ProfilePage-card .interest img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
} */
.ProfilePage-interest-item-holder{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.ProfilePage-interest-item {
  min-width: fit-content;
  border: 1px solid #e1dbff;
  box-sizing: border-box;
  border-radius: 40px;
  order: 2;
  flex-grow: 0;
  padding: 10px;
  text-align: center;
}
.ProfilePage-interest-icon-text{
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.ProfilePage-interest-icon-text img{
  width: 28px;
  height: 28px;
  margin-right: 5px;
}
.card-skills .skill:last-of-type {
  margin-right: 0;
}

.projects .no-idea,
.ideas .no-idea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-idea img {
  width: 90px;
  height: 90px;
  margin: 1em 0 2em;
}

.no-idea button {
  font-weight: bold;
  margin-bottom: 2em;
}

.ProfileEditor-modal .ant-modal-body {
  padding: 24px 100px;
}

.about-bio{
	margin-top: 10px;
  white-space: break-spaces;
}

@media (max-width: 900px) {
  .ProfilePage-body {
    padding: 10px;
  }

  .ProfilePage-header {
    flex-direction: column;
  }


  .ProfilePage-title-details {
    display: flex;
    flex-direction: column;
  }

  .ProfilePage-title-details .detail {
    margin-bottom: 10px;
  }

  .ProfilePage-cards {
    margin-top: 20px;
  }

  .ProfilePage-card {
    padding: 20px 10px;
  }


  .ProfilePage-card.about,
  .ProfilePage-card.interests,
  .ProfilePage-card.projects,
  .ProfilePage-card.ideas {
    grid-column: 1 / -1;
  }

  .ProfilePage-card.about {
    grid-row: 1 / 2;
  }

  .ProfilePage-card.interests {
    grid-row: 2 / 3;
  }

  .ProfilePage-card.skills {
    grid-row: 3 / 4;
  }

  .ProfileEditor-modal .ant-modal-body {
    padding: 10px;
  }
}

.greeting-name-profile {
  color: var(--primary-blue);
}

.message-btn-profile {
  font-weight: 900;
  color: white;
  background-color: var(--primary-blue);
  margin-left: 39px;
}
.message-btn-profile-mobile{
  display: none
}
.card-title.progress-title {
  font-size: 24px;
  font-weight: 800;
}

.progress-bar-profile {
  color: var(--primary-blue);
  font-size: 36px;
  font-weight: 800;
  margin-left: 24px;
}

.ProfilePage-title-details .detail {
  font-size: 14px;
  font-weight: 400;
  word-break: initial !important;
}

.ProfilePage-title-greeting {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.03em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.progress-desc-profile {
  font-size: 14px;
  font-weight: 400;
  vertical-align: super;
}

.ProfilePage-card-progress {
  border-radius: 20px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.078);
  padding: 1.5em;
  margin-right: 24px;
  width: 31%;
  display: inline-block;
  margin-bottom: 32px;
}

.progress-edit-profile i {
  color: var(--primary-blue);
  font-size: 18px;
}

.progress-edit-profile {
  color: var(--primary-blue);
  font-size: 16px;
  width: fit-content;
  float: right;
}

.progress-edit-profile:hover {
  cursor: pointer;
}

.progress-details-title {
  font-size: 14px;
  font-weight: 800;
  display: inline-block;
}

.progress-details-desc {
  font-size: 14px;
  font-weight: 400;
}

.progress {
  border-left: 4px solid var(--primary-blue);
  background-color: #fbfaff;
}

.social-links-profile {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: 15%;
  grid-gap: 5px;
  gap: 5px;
  /*! float: right; */
}
.social-links-profile > a:nth-child(1) {
  height: fit-content;
  width: fit-content;
 }
 

.social-links-profile a img {
  height: 35px;
}

.ProfilePage-card.about {
  width: 60%;
}

.ProfilePage-card.skills {
  width: 35%;
  margin-left: auto;
}

.ProfilePage-card.skills .card-skills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.card-skills .skill {
  font-weight: 400;
  padding: 8px;
  border: 1px solid #e1dbff;
  border-radius: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  width: fit-content;
}


.ProfilePage-card.interests {
  width: 100%;
}



.ProfilePage-card.ideas {
  margin-left: auto;
}

.nav-buttons-profile {
  margin-top: 37px;
  display: flex;
  justify-content: space-between;
}

.nav-buttons-profile p {
  font-weight: 600;
  color: var(--primary-blue);
  line-height: 25px;
  min-width: 79px;
  text-align: center;
}

.nav-buttons-profile p:hover {
	cursor: pointer;
}

.nav-buttons-profile p img {
  margin: 4px;
  vertical-align: bottom;
}

.profile-projects-title-container {
  display: flex;
  justify-content: space-between;
}

.profile-projects-title-container button {
  font-weight: 900;
  width: 144px;
}

.dots-navigation-profile {
  display: inline;
  font-size: 40px;
}

.dots-navigation-profile-active {
  display: inline;
  font-size: 50px;
}

.pagination-desktop-profile>ul>.ant-pagination-next {
  display: none;
}
.pagination-desktop-profile>ul>.ant-pagination-prev {
  display: none;
}

.profile-details-done {
  display: inline-block;
  float: right;
}

.profile-oneliner {
  margin-top: 20px;
}

.profile-oneliner img {
  width: 15px;
  margin-right: 8px;
  display: inline-block;
}

.profile-oneliner p {
  display: inline;
}

.pagination-mobile-profile{
	display: none;
}




.checklist h2{
	font-size: 16px;
	font-weight: 800;
}
.checklist-elements{
  display: flex;
}


@media (max-width: 1400px) {

  .ProfilePage-card-progress {
    width: 30%;
  }
}

@media (max-width: 1067px) {

  .ProfilePage-title {
    width: 69%;
  }
}

@media (max-width: 969px) {
  .ProfilePage-image {
    position: relative;
    margin: auto;
    margin-bottom: 15px;
  }
  .ProfilePage-image-profile-container {
    width: 220px;
    height:  220px;
    border-radius: 50%;
    border: 10px solid white;
    background: white;
  }

  .ProfilePage-title {
    width: 100%;
  }
  .message-btn-profile{
    display: none
  }
  .message-btn-profile-mobile{
    display: inline;
    font-weight: 900;
    color: white;
    background-color: var(--primary-blue);
    
  }
  .social-links-profile-mobile{
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .social-links-profile-mobile > a:nth-child(1) > img:nth-child(1){
    height: 50px;
    width: 50px;
   }
   
  .ProfilePage-title-details {
    flex-wrap: wrap;
  }
  .ProfilePage-title-details button {
    margin-top: 10px;
    margin-left: 0;
  }
  .checklist-elements{
    display: block;
  }
  .social-links-profile {
   display: none;
  }
  .ProfilePage-card-progress {
    width: 100%;
  }
  .ProfilePage-card {
    width: 100% !important;
  }
  .ProfilePage-header{
    margin-top: -130px;
  }
}

@media (max-width: 700px) {
  .ProfilePage-image {
    margin: auto;
    margin-bottom: 15px
  }
	.pagination-desktop-profile{
		display: none;
	}
	.pagination-mobile-profile{
	  display: flex;
	  justify-content: center;
  }

}
