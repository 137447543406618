
.user-data-holder {
  display: flex;
  justify-content: space-between;
  /* align-content: center; */
  align-items: center;
  margin-bottom: 8px;
}
.delete-member-icon {
  margin-right: 27.75px;
}

.delete-member-icon i{
  position: unset;
  font-size: unset;
  top: 0;
  right: 0;
}
.profileimg-name {
  display: flex;
  align-items: center;
}
.profileimg-name .user-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  color: #7c6de4;
}

.input-holder {
  margin-right: 23px;
}
.input-holder > div > input,
.input-holder > div > textarea {
  border: 1px solid #282828;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 213.75px;
  max-width: 213.75px;
}
.user-title-input {
  max-height: 32px;
  margin-bottom: 8px;
}
.user-title-input [type="text"] {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  padding: 8px 10px;
  color: #282828;
}
.user-description-input{
  margin-bottom: 25px;
}
.user-description-input textarea {
  padding: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #282828;
  min-height: 100px;
  max-height: 100px;
}
.profile-img{
    margin-right: 8px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    border-radius: 50%;
    background-position: center;
}
