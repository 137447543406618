.login_holder {
  position: absolute;
  width: 463px;
  height: 463px;
  background: #ffffff;
  box-shadow: 4px 5px 24px rgba(30, 13, 98, 0.14);
  border-radius: 10px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login_header {
  width: 100%;
  height: 64px;
  background: #6755ce;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: 40px;
}
.login_header h1 {
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 16px 40px;
  position: absolute;
}
.content_holder {
  margin-left: 40px;
}
.input input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;
  position: static;
  width: 88%;
  height: 40px;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  border-radius: 3px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px 0px 0px;
}
.input::placeholder {
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.input svg {
  margin-top: 8px;
}
.login_btn {
  width: 144px;
  height: 40px;
  margin-top: 12px;
  margin-right: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
.forgott_pw {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #6755ce;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.registration_holder {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: baseline;
}
.registration_link {
  margin-left: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #6755ce;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.bg_image {
  transform: rotate(9.18deg);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
  width: 350px;
  height: 350px;
  position: absolute;
  top: 60%;
  left: 75%;
  transform: translate(-50%, -50%);
}
@media (min-width: 500px) and (max-width: 1000px) {
  .login_holder {
    position: absolute;
    width: 400px;
    height: 400px;
    background: #ffffff;
    box-shadow: 4px 5px 24px rgba(30, 13, 98, 0.14);
    border-radius: 10px;
    z-index: 1;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .login_header {
    width: 100%;
    height: 50px;
    background: #6755ce;
    border-radius: 16px 16px 0px 0px;
    margin-bottom: 20px;
  }
  .login_header h1 {
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin: 10px 20px;
    position: absolute;
  }
  .content_holder {
    margin-left: 20px;
  }
  .forgott_pw {
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #6755ce;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
  }
  .bg_image {
    transform: rotate(9.18deg);
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    background-position: center;
    width: 320px;
    height: 320px;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 499px) {
  .login_holder {
    position: absolute;
    width: 300px;
    height: 300px;
    background: #ffffff;
    box-shadow: 4px 5px 24px rgba(30, 13, 98, 0.14);
    border-radius: 10px;
    z-index: 1;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .login_header {
    width: 100%;
    height: 35px;
    background: #6755ce;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 10px;
  }
  .login_header h1 {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin: 6px 10px;
    position: absolute;
  }
  .content_holder {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .input input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 4px;
    position: static;
    width: 88%;
    height: 30px;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 3px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px 0px 0px;
  }
  .input::placeholder {
    font-family: Inter;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: left;
  }
  .input svg {
    margin-top: 2px;
  }
  .login_btn {
    width: 72px;
    height: 30px;
    margin-top: 6px;
    margin-right: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
    color: #ffffff;
    padding: 5px;
  }
  .forgott_pw {
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 10px;
    text-decoration-line: underline;
    color: #6755ce;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
  .registration_holder {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: baseline;
  }
  .registration_holder h3 {
    font-size: 10px;
  }
  .registration_link {
    margin-left: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #6755ce;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
  .bg_image {
    display: none;
  }
}
