.social{
  margin-top: 32px;
}
.social h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 8px;
}
.socialTextBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 16px;
}
.socialTextBoxmodified{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 16px;
}
.socialTextBoxmodified .socialText{
  margin-right: 45px;
}
.socialText {
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  color: #262626;
  /* margin-right: 35px; */
}
.star {
  color: #6755ce;
  margin-right: 8px;
  margin-left: 2px;
}
@media (max-width: 700px) {
  .socialText {
    font-size: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    color: #262626;
    /* margin-right: 35px; */
  }
  .socialTextBoxmodified .socialText {
   margin-right: 0px;
  }
}