@media (min-width: 760px) {
    .jury-table-mobile {
        display: none;
    }
}

@media (max-width: 759px) {
    .jury-table-desktop {
        display: none;
    }
}

@media (min-width: 400px) {
    .jury-mobile-avg-body {
        display: none;
    }
}

@media (max-width: 399px) {
    .jury-mobile-avg-head {
        display: none;
    }
}