.package-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    width: 21%;
    min-width: fit-content;
}

.package-card-header {
    height: 16px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 24px 24px 0px 0px;
}

.package-card-title {
    margin-top: 56px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #262626;
}

.package-card-title-desc {
    margin: 0 auto;
    margin-top: 6px;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #262626;
    padding-bottom: 31px;
    border-bottom: 1px solid #E1E1E1;
    width: 65%;
}

.package-card-list {
    margin-top: 30px;
    width: 353px;
    height: 600px;
}

.package-card-list>li {
    max-width: 83%; 
    font-size: 16px;
    line-height: 32px;
}

.package-card-monthly {
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #262626;
    margin-top: 23px;
}

.package-card-annual{
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #262626;
}

.package-card-button-container-more{
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    margin-top: 24px;
}

.package-card-button-container{
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    margin-top: 55px;
}

.package-card-button-container-more > button{
    width: 85%;
}

.package-card-button-container > button {
    width: 85%;
}

.free-dynamic-color {
    background: #C4C4C4;
    border-color: #C4C4C4;
}

.basic-dynamic-color {
    background: #AFA3F4;
    border-color: #AFA3F4;
}

.pro-dynamic-color {
    background: #6755CE;
    border-color: #6755CE;
}

.pro-package-card{
    border: 3px solid #6755CE;
    border-top: 16px solid #6755CE;
}

.enterprise-dynamic-color {
    background: #AFA3F4;
    border-color: #AFA3F4;
}

.line-through{
    text-decoration-line: line-through;
}

.higher-font{
    font-size: 19px !important;
    line-height: 32px !important;
}

.package-card-title-desc-free{
    margin-top: 38px;
}
.sales-questions-answers > div > div > div > div > p{
    color: #262626;
}