.GroupPage-boxes {
    display: flex
}

@media screen and (max-width: 1100px) {
    .GroupPage-box {
    padding:0 !important;
    }
}
.GroupPage-box {
    border-radius: 9px;
    box-sizing: content-box;
    display: inline-flex;
    min-width: 148px;
    height: 48px;
    padding: 8px;
    margin: 6px;
    text-align: center;
    box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.04),
    2px 4px 10px rgba(0, 0, 0, 0.04),
    4px -8px 12px rgba(0, 0, 0, 0.03),
    8px 16px 16px rgba(0, 0, 0, 0.03),
    16px 32px 32px rgba(0, 0, 0, 0.02),
    32px 64px 64px rgba(0, 0, 0, 0.02)
}

.GroupPage-head {
    display: flex;
    justify-content: space-between;
    padding: 16px 62px 62px;
}

.GroupPage-left {
     width: 300px
}

.GroupPage-image img {
    max-height: 256px;
    border-radius: 16px;
    max-width: 500px;
}

.GroupPage-right {
    min-width: 500px
}

.GroupPage-box-text {
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.GroupPage-bar {
    display: grid;
    grid-template-columns: calc(33% - 10px) calc(33% - 12px) calc(33% - 10px);
    gap: 16px;
    margin-top: 8px;
}

.GroupMembers-profile-image {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
}

.GroupPage-box-icon {
    width: 32px;
    height: 32px;
    margin-left: 8px;
    margin-top: 8px;
    margin-right: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.GroupPage-bar-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%
}

.GroupPage-button-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-block;
}

.GroupPage-body {
    margin-top: 32px
}

@media (max-width: 1000px) {
    .GroupPage-head {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .GroupPage-head {
        padding: 16px 0 0;
    }
    .GroupPage-right {
        min-width: 100%;
        text-align: center;
    }
    .GroupPage-bar {
        grid-template-columns: none;
        display: flex;
        justify-content: space-between;
    }
}