.modal {
    width: 75% !important;
  max-height: 500px;
  height: 100%;
}
.modal div.ant-modal-body:nth-child(1) {
  height: fit-content;
  width: 100%;
}
.cropper {
  width: 100%;
  max-height: 100%;
  height: 100%;
}
.cropper_holder {
  width: 100%;
  max-height: 700px;
}