.social_btn {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px !important;
  line-height: 16px;
  min-width: 225px;
}
.social_btn svg {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}
@media (max-width: 350px) {
  .social_btn {
    min-width: 185px;
  }
}