.GroupEdit .body {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 32px;
}

.GroupEdit .headline {
    padding: 16px 32px;
}

.GroupEdit .images {
    display: inline-flex;
    justify-content: center;
}

.GroupEdit .ImageUploader {
    margin: 0 16px;
}