.Card-header {
  margin-bottom: 32px;
  margin-top: 23px;
}
.Card-header .title {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  min-height: 32px;
}
.Card-header .title-with-button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
.button-holder {
  position: static;
  top: 0px;
}
.Card-header .title-with-button .canJoin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  width: 151px;
  height: 32px;
  /* primary */
  background: #7c6de4;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  border: none;
}
.Card-header .title-with-button .leave {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  width: 151px;
  height: 32px;
  /* primary */
  background: #7c6de4;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  border: none;
}

.Card-header .title-with-button .withdraw {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;
  width: 180px;
  height: 32px;
  /* primary */
  background: #ffffff;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  color: #7c6de4;
  border: 2px solid #7c6de4;
  box-sizing: border-box;
  border-radius: 4px;
}
.Card-header .subtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  margin-bottom: 16px;
}
.Card-header .description {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #282828;
}

.card-title-homepage-navigate{
  margin-top: 24px;
  font-size: 14px;
  line-height: 16px;
  color: #6755CE;
}