.Ideas-form-container {
	padding: 2.5em 5em;
	padding-bottom: .5em;
	box-shadow: 0 0 19px -7px rgb(0,0,0,.1);
	border-radius: 10px;
	margin-top: 3em;
}

.Ideas-form-headline {
	display: flex;
	justify-content: space-between;
}

.Ideas-form-title,
.Ideas-top-title {
	font-size: 1.5rem;
	font-weight: 900;
	margin-bottom: 1.5em;
}

.Ideas-form-close {
	font-size: 1.5rem;
	cursor: pointer;
}

.Ideas-form {
	display: grid;
	grid-template-columns: 2fr 2fr 1fr 1fr;
	grid-auto-rows: auto;
	gap: 2px;
}

.Ideas-form .ant-select-search--inline .ant-select-search__field {
	font-size: 12px;
	margin-left: 4px;
	margin-top: -2px;
}

.Ideas-form-item.name {
	grid-column: 1 / 3;
}

.Ideas-form-item.interests {
	grid-column: 1 / 3;
}

.Ideas-form-item.details {
	grid-column: 1 / 3;
	grid-row: 3 / 5;
}

.Ideas-form-item.image {
	grid-column: 3 / 5;
	grid-row: 1 / 3;
	justify-self: center;
}

.Ideas-form-item.shareable {
	grid-column: 3 / 4;
	grid-row: 3 / 4;
	justify-self: center;
}

.Ideas-form-item.commentable {
	grid-column: 4 / 5;
	grid-row: 3 / 3;
	justify-self: center;
}

.Delete-button {
	grid-column: 3 / 5;
	grid-row: 4 / 5;
	justify-self: center;
}

.Ideas-form-item.submit {
	grid-column: 1 / -1;
	justify-self: center;
}

.Ideas-form-item.submit button {
	font-weight: bold;
	padding: 0 3em;
}

@media (max-width: 930px) {
	.Ideas-form-container {
		padding: 20px 25px;
	}

	.Ideas-form {
		grid-template-columns: 0 !important;
	}

	.Ideas-form-item.image{
		grid-column: 2 / 4;
		grid-row: 1 / 2;
		margin-bottom: 15px;
	}

	.Ideas-form-item.name {
		grid-column: 4 / 1;
		grid-row: 2 / 3;
	}

	.Ideas-form-item.interests {
		grid-column: 4 / 1;
		grid-row: 3 / 4;
	}

	.Ideas-form-item.details {
		grid-column: 4 / 1;
		grid-row: 4 / 5;
	}
	
	.Ideas-form-item.shareable {
		grid-column: 1 / 3;
		grid-row: 5 / 6;
	}

	.Ideas-form-item.commentable {
		grid-column: 3 / 3;
    	grid-row: 5 / 7;
	}

	.Ideas-form-item.submit {
		grid-column: 2 / 4;
    	grid-row: 7 / 7;
		top:20px;
	}

	.Delete-button {
		grid-column: 1 / 3;
		grid-row: 7 / 8;
		margin-bottom: 30px;
	}

	.Ideas-form-container {
		position: relative;
	}

	.Ideas-form-close {
		position: absolute;
		top: 20px;
		right: 20px;
	}
}