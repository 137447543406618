.Ideas-top-container {
	padding: 2.5em 5em;
	padding-bottom: 0.5em;
	box-shadow: 0 0 19px -7px rgb(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 3em;
  }
  
  .Ideas-top-container {
	padding-bottom: 2.5em;
  }
  
  .Ideas-top-options {
	display: flex;
	justify-content: space-between;
  }
  
  .Ideas-top-options .option-add button {
	font-weight: bold;
  }
  
  .Ideas-pagination {
	margin-top: 4em;
	text-align: center;
  }
  
  .Ideas-pagination-bottom {
	margin-bottom: 4em;
	text-align: center;
  }
  
  .Ideas-list {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px; /* gutter size offset */
	width: auto;
	margin: 4em 0;
  }
  
  .Ideas-list-column:nth-of-type(2) {
	margin-left: 30px; /* gutter size */
  }
  
  .Idea-card {
	margin-bottom: 30px;
	/* min-height: 750px; */
  }
  
  .no-result {
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	margin: 70px 0;
	color: rgb(94, 94, 94);
  }
  
  .card-team {
	padding: 16px 14px;
	box-shadow: 10px 10px 20px #00000014;
	border-radius: 10px;
	background-color: white;
  }
  
  .card-title {
	font-size: 21px;
	font-weight: 900;
	margin-bottom: 1.5em;
  }
  
  .member {
	display: flex;
	margin-bottom: 24px;
	position: relative;
	align-items: center;
  }
  
  .member i {
	position: absolute;
	font-size: 15px;
	top: 2px;
	right: 4px;
	cursor: pointer;
  }
  
  .member i:hover {
	color: red;
  }
  
  .member img {
	width: 44px;
	height: 44px;
	margin-right: 9px;
	border-radius: 50%;
  }
  
  .member .member-text-name {
	font-weight: 900;
	font-size: 13px;
  }
  
  .member .member-text-title {
	font-weight: 300;
	font-size: 13px;
	margin-bottom: 4px;
  }
  
  .member .member-text-desc {
	font-size: 12px;
  }
  
  @media (max-width: 600px) {
	.Ideas-top-container {
	  text-align: center;
	}
  
	.Ideas-top-options {
	  display: unset;
	}
  
	.Ideas-top-options .option-search {
	  margin-top: 40px;
	}
  }
  