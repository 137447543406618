.ant-select-selection--multiple .ant-select-selection__rendered {
    position: revert;
}

.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-select-selection--multiple .ant-select-selection__choice__content {
    display: flex;
    justify-content: center;
    align-items: center;
}