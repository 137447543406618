.form-header {
  background-color: #6755ce;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.button-container {
  display: flex;
  flex-direction: column;
}
.buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-row {
  display: flex;
  flex-direction: row;
  gap: 25px;
  width: 100%;
}
.check-row{
  display: flex;
  flex-direction: row;
  gap: 25px;
  width: 100%;
  margin-bottom: 40px;
}
.colored-text {
  color: #6755ce;
}
.form-header {
  margin: -24px !important;
  padding: 16px;
}
.registration-subtitle {
  display: flex;
  flex-direction: row;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: #6755ce;
  margin-top: 40px;
  margin-bottom: 40px;
}
.login-container {
  display: flex;
  gap: 20px;
  margin-top: 32px;
}
.login-description {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
}
.login-text {
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #6755ce;
}
.grecaptcha-badge {
  display: none !important;
 }
 
.recaptcha {
  display: flex;
  align-items: center;
  align-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  color: #262626;
  margin-top: 25px;
}
.registration_hr {
  width: 100%;
  opacity: 0.21;
  border: 0.5px solid #262626;
  margin-top: 20px;
  height: 0;
}
.ant-modal-content {
  border-radius: 16px !important;
}
.ant-modal-close-icon > svg:nth-child(1) {
  color: white;
}
.subtitle-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tooltip{
  background-color: #ffffff;
  z-index: 10;
  position: absolute;
  height: 50px;
  margin-top: -110px;
}
.tooltip-icon{
  width: 15px;
  height: 15px;
  margin-left:5px;
  filter: opacity();
}

@media (max-width: 580px) {
  .registration-modal-view {
    width: 80% !important;
  }
  .form-header{
    font-size: 15px;
    padding: 15px;
  }
  .buttons-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .registration-subtitle {
    display: flex;
    flex-direction: row;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #6755ce;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .input-row {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
  }
  .check-row{
    margin-bottom: 0;
  }
}

.registration-tooltip-icon svg{
  color: var(--primary-blue);
  border-radius: 100%;
  width: 20px;
}