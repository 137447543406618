.ResetPassword {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.ResetPassword-form {
    width: min(350px, 90%);
    padding: 3em;
    box-shadow: 0 0 19px -7px rgb(0,0,0,.2);
    border-radius: 5px;
}

.ResetPassword-form-button {
    margin-bottom: 10px;
}

.ResetPassword-button {
    margin-bottom: 0px;
}
