.Team h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0;
}
.member-holder {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.member {
  position: unset;
  margin-left: 24px;
  margin-bottom: 0;
  align-items: center;
}
.member i {
  position: unset;
  font-size: 12px;
  top: 0;
  right: 0;
  cursor: pointer;
}
.new-member-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
  /* greys/grey 1 */
  color: #282828;
  /* margin-left: 24px; */
  margin-bottom: 12px;
}
.display-team-members {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.team-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.team-btn {
  width: 151px;
  height: 32px;
  font-weight: 900;
}
.btnWithdraw {
  width: 170px;
  background: #fff;
  color: #7c6de4;
  margin-right: 24px;
  height: 32px;
  font-weight: 900;
  padding: 0;
}

.display-member {
  margin-top: 24px;
  justify-content: flex-start;
}

.display-team-members::after {
  content: "";
  flex: auto;
}

.new-member-holder {
  margin-top: 10px;
}
@media (min-width: 320px) and (max-width: 512px) {
  .team-header {
    display: block;
    margin-bottom: 0px;
  }
  .display-team-members {
    justify-content: start;
    flex: none;
  }.display-team-members {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
  }
  .display-member {
    margin-top: 16px;
    justify-content: flex-start;
  }
}
@media (min-width: 513px) and (max-width: 960px) {
  .team-header {
    display: block;
  }
  .Team h2 {
    width: 100%;
  }

  .team-button-holder {
    margin-top: 20px;
  }

  .display-team-members {
    flex: none;
  }

  .display-member {
    flex: none;
  }

  .display-team-members::after {
    flex: none;
  }
}
.new-member-title-description {
  font-size: 11px;
  margin-top: -12px;
}
