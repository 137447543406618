.organizationpage-container {
  max-width: 1280px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 164px;
  margin-top: 40px;
}

.organizationpage-title-container {
  width: 100%;
  height: 331px;
}

.organizationpage-cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.organizationpage-title-card {
  box-shadow: rgb(0 0 0 / 8%) 10px 10px 24px;
  background-color: rgb(255, 255, 255);
  margin-top: -80px;
  padding: 20px 48px 20px 48px;
  border-radius: 15px;
  max-width: 838px;
  height: fit-content;
  position: relative;
  margin-left: -8px;
}

.organizationpage-title-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
}

.organizationpage-title-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}

.organizationpage-title-card-menu {
  display: flex;
  gap: 16px;
  margin-right: -13px;
  margin-left: 4px;
}

.organizationpage-title-card-menu span {
  font-weight: 900;
  font-size: 31px;
  max-height: 31px;
  cursor: pointer;
  min-width: 40px;
}

.organizationpage-title-card-button-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 4px;
}

.organizationpage-section-buttons {
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.organizationpage-section-buttons:hover {
  cursor: pointer;
}

.organizationpage-logo-image-profile-container {
  position: absolute;
  overflow: hidden;
  width: 204px;
  height: 204px;
  border-radius: 50%;
  border: 12px solid white;
  background: white;
  margin-top: -190px;
  z-index: 1;
  left: calc(50vw + 400px);
}
.organizationpage-logo-image-logo {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.organizationpage-details-container {
  margin-top: 48px;
}

.organizationpage-details {
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 60px;
}

.organizationpage-about-org {
  margin-top: 28px;
}

.organizationpage-about-org div {
  margin-right: 16px;
}

.organizationpage-about-org span img {
  max-width: 18px;
  max-height: 18px;
  margin-right: 8px;
}

.organizationpage-short-description-org {
  margin-top: 28px;
  margin-left: 40px;
}

.organizationpage-short-description-org img {
  max-width: 18px;
  max-height: 18px;
  margin-right: 8px;
}

.organizationpage-social-org {
  margin-top: 28px;
}

.organizationpage-social-org button {
  font-weight: 900;
  font-size: 16px;
  margin-left: 28px;
}

.organization-about-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 48px;
  margin-left: 40px;
  margin-right: 40px;
}

.organization-about-description {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 24px;
  width: 60%;
  overflow-wrap: break-word;
  word-break: break-word;
}

.organization-about-interests {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 24px;
  margin-top: 20px;
}
.organization-about-interest-holder{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.organization-about-interests h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}

.organization-about-interest-icon {
  max-height: 32px;
  max-width: 32px;
  display: inline-block;
  margin-right: 8px;
}

.organizationpage-about-video {
  margin-bottom: 16px;
}
.organization-about-team {
  width: 100%;
}
.organization-about-team h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}

.organization-about-partners h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
}

.organizationpage-about-partners {
  box-shadow: rgb(0 0 0 / 10%) 3px 3px 14px;
  background-color: rgb(255, 255, 255);
  width: 214px;
  height: 120px;
  border-radius: 3px;
  cursor: pointer;
}

.organizationpage-about-partners img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.organizationpage-about-partners-name {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 900;
  margin-top: 8px;
}

.organization-about-partner-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.organization-users-box {
  box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.04), 2px 4px 10px rgba(0, 0, 0, 0.04),
    4px -8px 12px rgba(0, 0, 0, 0.03), 8px 16px 16px rgba(0, 0, 0, 0.03),
    16px 32px 32px rgba(0, 0, 0, 0.02), 32px 64px 64px rgba(0, 0, 0, 0.02);
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 215px;
  border-radius: 10px;
  width: 23%;
}

.organization-user-left {
  width: 130px;
  text-align: center;
  padding-top: 30px;
}

.organization-users-image {
  position: relative;
}

.organization-users-image-main {
  width: 130px;
  height: 130px;
  border: 10px solid white;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  border-radius: 50%;
  background-position: center;
}

.organization-users-image-role {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 78px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.078);
}

.organization-user-right {
  width: 170px;
  height: 210px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.organization-user-right button {
  background-color: white;
  color: var(--primary-blue);
  font-weight: 900;
  font-size: 16px !important;
  width: 112px;
  height: 32px;
}

.organization-users-role,
.organization-users-bio {
  word-break: break-word;
  margin-bottom: 8px;
}

.organization-users-box > div {
  display: flex;
  justify-content: space-between;
}

.organization-interest-error {
  font-size: 14px;
  font-weight: 600;
  color: #f5222d;
  margin-top: 3px;
}

.organization-about-others {
  width: 450px;
}

.organizationpage-social-org > a > img {
  max-width: 32px;
  max-height: 32px;
  margin-left: 16px;
}

.organization-follower-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 48px;
  margin-left: 40px;
  margin-right: 40px;
}

.organization-followers-title {
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
}

.organization-follower-counter {
  padding-top: 4px;
}

.organization-users-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.organization-about-description-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
}
.organization-about-description-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 16px;
}

.is-organization-partner {
  color: var(--primary-blue);
  font-weight: 700;
}

.organization-competition-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 48px;
  margin-left: 40px;
  margin-right: 40px;
}

.organization-competitions-title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.organization-competitions-title-container > button {
  font-size: 16px;
  font-weight: 900;
  width: 150px;
  height: 40px;
}

.organization-competition-title {
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  display: inline-block;
}

.organization-competition-counter {
  padding-top: 4px;
}

.organization-competition-container > div > .card {
  width: 48%;
}

.organization-competition-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.organization-competition-content-box-view {
  display: flex;
  gap: 35px;
  width: 100%;
}
.organization-pagination ul {
  margin: 0 auto;
}

.organization-pagination {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 15px;
}

.organization-sorter {
  border: 1px solid #737373;
  border-radius: 4px;
  padding: 4px 10px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-top: -39px;
  margin-bottom: 32px;
  margin-right: 16px;
}

.organization-boxview {
  margin-left: unset;
  margin-right: unset;
}

.organization-listview-startup-logo {
  max-width: 32px;
  max-height: 32px;
}

.organization-listview-startup-columns th,
.organization-listview-startup-columns td {
  padding-right: 24px;
  height: 32px;
  padding-top: 16px;
  width: fit-content;
  word-break: keep-all;
}
.organization-listview-startup-columns th {
  white-space: nowrap;
}

.organization-portfolio-dropdown-container {
  display: flex;
  margin-left: auto;
}

.organization-listview-startup-table {
  width: 100%;
}

.organization-search-suffix {
  background-color: var(--primary-blue);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  text-align: center;
  padding-top: 15px;
  margin-left: 2px;
  position: absolute;
}

.organization-portfolio-search {
  margin-top: -39px;
  margin-bottom: 32px;
  max-width: 250px;
}

.organization-listview-delete-icon:hover {
  cursor: pointer;
}

.organization-about-interest {
  border: 1px solid #e1dbff;
  border-radius: 40px;
  padding: 10px;
  width: fit-content;
  white-space: nowrap;
  padding-right: 42px;
}

.organization-description-preview > .prosemirror-editor {
  min-height: inherit;
}

.organization-description-preview > .prosemirror-editor > .ProseMirror {
  min-height: inherit;
}

.organization-followers-pagination {
  display: block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.organization-details-element {
  display: inline-block;
}

.organizations-nothing-to-show {
  text-align: center;
  margin: auto;
  font-size: 22px;
  font-weight: 900;
}

.organization-portfolio-date-selector{
  color: var(--primary-blue);

}

.organization-portfolio-date-selector:hover{
  cursor: pointer;
  color: var(--secondary-blue);
}
.create-new-modal{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.contact-email{
  color:#6755CE;
  font-weight: 700;
}
.announcements-main{
  margin-top: 48px;
  margin-left: 40px;
}
.announcements{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.announcements-container{
  width: 700px;
}
@media (max-width: 1300px) {
  .organization-users-box {
    width: 31%;
  }

  .organization-about-video > div > .embed-video > iframe {
    width: 350px !important;
  }

  .organization-about-others {
    width: 400px;
  }

  .organizationpage-logo-image-profile-container {
    left: calc(50vw + 300px);
  }
}

@media(max-width:1200px){
  .organization-interests>.select-items-container{
    justify-content: left;
  }
  
}

@media (max-width: 1050px) {
  .organization-about-description {
    width: 55%;
  }

  .organizationpage-logo-image-profile-container {
    left: calc(40vw);
    margin-top: -350px;
  }

  .organizationpage-title-card {
    margin-top: 80px;
  }
  .organizationpage-details-container {
    margin-top: 208px;
  }
}

@media (max-width: 990px) {
  .organization-users-box {
    width: 45%;
  }
}

@media (max-width: 935px) {
  .organization-about-description {
    width: 100%;
  }
  .organization-about-others {
    width: 100%;
  }
  .organization-about-video > div > .embed-video > iframe {
    width: 450px !important;
  }
}

@media (max-width: 850px) {
  .organization-competition-content > .card {
    width: 100% !important;
  }

  .organization-sorter {
    margin-top: unset;
  }

  .organization-portfolio-search {
    margin-top: unset;
  }
}

@media (max-width: 720px) {
  .organizationpage-details-container {
    margin-top: 275px;
  }
}

@media (max-width: 720px) {
  .organization-users-box {
    width: fit-content;
  }

  .organization-competition-container {
    flex-direction: column;
  }

  .organization-portfolio-search {
    margin-top: 20px;
    height: 32px;
  }

  .organization-portfolio-dropdown-container {
    margin-right: auto;
    margin-left: initial;
  }

  .organizationpage-logo-image-profile-container {
    margin-top: -390px;
  }
}

@media (max-width: 550px) {
  .organization-about-video > div > .embed-video > iframe {
    width: 100% !important;
  }
}

@media (max-width: 430px) {
  .organizationpage-details-container {
    margin-top: 330px;
  }

  .organizationpage-logo-image-profile-container {
    left: calc(30vw);
    margin-top: -445px;
  }
}
