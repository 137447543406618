.display-team-header {
  display: flex;
  margin-bottom: 16px;
}
.display-team-img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 50%;
}
.member-text-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  color: #7c6de4;
  margin-bottom: 8px;
}
.member-text-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #282828;
}
.member-text-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #282828;
  min-width: 200px;
  max-width: 200px;
  margin-right: 24px;
}
