 .zoom {
    cursor: pointer;
    /* transition: transform .1s; */
}
/*
.zoom:hover {
    transition: transform .1s;
    -webkit-transform: scale3d(1.06, 1.06, 1);
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
} */
.btn{
    min-width: 150;
    margin-left: auto;
    margin-top: -63px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
}
@media (max-width: 719px) {
.btn {
    margin-top: 0 !important;
    height: 35px !important;
    
}
}