.member {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 20px;
}
.btn {
  font-weight: "bold";
  width: 83px;
  height: 25px;
  margin-top: 5px;
}
.input_title {
  width: 93%;
  margin-bottom: 8px;
  height: 32px;
  min-width: 200px;
  max-width: 200px;
}
.input_description textarea {
  border: 1px solid #282828;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 200px;
  max-width: 200px;
  max-height: 100px;
}
.btnDeny {
  font-weight: "bold";
  width: 83px;
  height: 25px;
  background-color: white;
  color: var(--primary-blue);
  border: 2px solid var(--primary-blue);
  margin-left: 16px;
}
.teamMemberPhoto {
  width: 44px;
  height: 44px;
  margin-right: 9px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  background-position: center;
}
.member_header {
  display: flex;
  align-items: center;
}
.member_title {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  color: #7c6de4;
}
.member_container {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 320px) and (max-width: 512px) {
  .member {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
