.Users-list > div {
    padding-bottom: 100px;
    flex-flow: row wrap;
    display: flex;
}
/*
.Users-role, .Users-bio {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
*/
.Users-role, .Users-bio {
    word-break: break-word;
}

.Users-search {
    width: 60%;
    margin-left: 20%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Users-image {
    position: relative;
}

.Users-image-main {
    width: 130px;
    height: 130px;
    border: 10px solid white;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    border-radius: 50%;
    background-position: center;
}

/* .Users-image-main img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
} */

.Users-image-role {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 2px;
    top: 78px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.078);
}

.Users-image-role img {
    width: 60%;
}

.Users-empty-state {
    text-align: center;
    color: rgb(68, 68, 68);
    width: 100%;
    margin-top: 20px;
    font-size: 15px;
}

.Users-box {
    box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.04), 2px 4px 10px rgba(0, 0, 0, 0.04), 4px -8px 12px rgba(0, 0, 0, 0.03), 8px 16px 16px rgba(0, 0, 0, 0.03), 16px 32px 32px rgba(0, 0, 0, 0.02), 32px 64px 64px rgba(0, 0, 0, 0.02);
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 180px;
    border-radius: 10px;
    width: 298px;
}

/*.Users-box img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
}*/

.Users-box > div {
    display: flex;
    justify-content: space-between;
}

.Users-box .left {
    width: 130px;
    text-align: center;
    padding-top: 30px;
}

.Users-box .right {
    width: 170px;
    height: 180px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Users-header {
    padding-top: 80px;
    padding-bottom: 40px;
    display: flex
}

.Users-rolefilter {
    margin-bottom: 20px;
    display: inline-block;
    width: 50%
}

.Users-header h1 {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2em;
}

.Users-header p {
    font-size: 20px;
    margin-top: 3em;
    line-height: 1.4em;
    font-weight: 300;
    max-width: 514px;
}

@media (min-width: 1400px) {
    .Content-container {
        max-width: 1300px;
    }
}
@media (max-width: 969px) {
    .Content-container {
        max-width: 672px;
    }

    .Users-header {
        display: block;
    }

    .Users-rolefilter {
        width: 100%;
    }
}
@media (max-width: 700px) {
    .Users-box {
        margin-right: 0;
    }
    .Content-container {
        max-width: 349px;
    }
}