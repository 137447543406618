.GroupCard {
  border-radius: 9px;
  box-sizing: content-box;
  cursor: pointer;
  display: inline-flex;
  width: 200px;
  height: 120px;
  margin: 6px;
  text-align: center;
  box-shadow: -3px 2px 2px rgba(0, 0, 0, 0.04), 2px 4px 10px rgba(0, 0, 0, 0.04), 4px -8px 12px rgba(0, 0, 0, 0.03), 8px 16px 16px rgba(0, 0, 0, 0.03), 16px 32px 32px rgba(0, 0, 0, 0.02), 32px 64px 64px rgba(0, 0, 0, 0.02);
  background-size: cover;
  background-position-y: 50%;
  background-position-x: center;
}

.GroupCard-bottom {
  position: absolute;
  bottom: 0;
  color: #fff;
  background: rgba(0,0,0,0.5);
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  width: 100%;
  text-align: left;
  padding: 0 12px;
}

.GroupCard-membersCount {
  position: absolute;
  top: 16px;
  background: var(--primary-blue);
  color: #fff;
  padding: 0px 8px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
