.CreateGroup {
    max-width: 1000px;
    width: calc(100vw - 24px);
    margin: 64px auto 0;
}

.CreateGroup .title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2em;
}

.CreateGroup .grid {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
}

.CreateGroup .appear {
    margin-top: 32px;
    margin-bottom: 32px;
}

.CreateGroup .invites {
    margin-bottom: 32px;
}
