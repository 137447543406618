@import '~antd/dist/antd.css';

:root {
	--primary-blue: #6655ce;
	--secondary-blue: #7c6de4;
	--max-width: 1100px;
}

html, body {
	padding: 0;
	margin: 0;
}

body {
	font-family: 'Inter', sans-serif !important;
	color: #262626;
	font-weight: light;	
}

div {
	word-break: break-word;
}

a:link, a:hover, a:active, a:visited {
	color: var(--primary-blue)
}

.clear {
	float: none;
	clear: both;
}




.layout {
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: minmax(0, 1fr)
}

.ant-btn-primary,
.ant-btn-primary:active {
	background-color: var(--primary-blue);
	border-color: var(--primary-blue);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
	background-color: var(--secondary-blue);
	border-color: var(--secondary-blue);
}

.ant-layout {
	background-color: #fff;
}

.Content-container {
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 .9em;
}

::placeholder, textarea::placeholder { /* WebKit, Blink, Edge */
  color:    #262626 !important;
	font-weight: 300 !important;
	font-size: 12px !important;
}

::-webkit-input-placeholder, textarea::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #262626 !important;
  font-weight: 300 !important;
	font-size: 12px !important;
}
::-moz-placeholder, textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:     #262626 !important;
 font-weight: 300 !important;
 opacity:  1;
 font-size: 12px !important;
}
:-ms-input-placeholder, textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:     #262626 !important;
 font-weight: 300 !important;
 font-size: 12px !important;
}
::-ms-input-placeholder, textarea::-ms-input-placeholder { /* Microsoft Edge */
 color:     #262626 !important;
 font-weight: 300 !important;
 font-size: 12px !important;
}

.ant-input,
.ant-select-selection,
.ant-input-number {
	font-size: 12px;
	border: 1px solid #262626;
	border-radius: 4px;
	opacity: 1;
}

.ant-select-selection,
.ant-input-number {
	font-weight: 900;
}

.ant-input {
	font-weight: normal;
}

.ant-btn {
	font-size: 14px !important;
}

.ant-select-lg {
	font-size: 12px;
}



.ant-upload-list-item-info {
	border-radius: 500px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
	padding: 0px;    
	border-radius: 500px;
}

.ant-upload-list-picture-card-container {
	margin: 0px;
}

/* selector */
.ant-pagination-item-active {
	border-color: var(--primary-blue);
}

.ant-pagination-item-active a {
	color: var(--primary-blue);
}

.ant-pagination-item-active:focus, .ant-pagination-item-active:hover {
	border-color: var(--secondary-blue);
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
	color: var(--secondary-blue);
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
	color: var(--secondary-blue);
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
	border-color: var(--secondary-blue);
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
	color: var(--secondary-blue);
	border-color: var(--secondary-blue);
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
	color: var(--secondary-blue);
	border-color: var(--secondary-blue);
}

.ant-pagination-prev:hover a, .ant-pagination-next:hover a {
	border-color: var(--primary-blue);
}

.ant-pagination-prev:hover a, .ant-pagination-next:hover a {
	border-color: var(--primary-blue);
}
/* ant button */
.ant-btn.pozi:hover, .ant-btn.pozi:focus {
	color: var(--primary-blue);
	background-color: #fff;
	border-color: var(--primary-blue);
}
/* .primary-blue {
	background-color: var(--primary-blue);
} */
.ant-checkbox-checked:after {
	border: none;
}
.ant-layout-content{
	margin-top: 90px;
	min-height: 95vh;
}