.aditional-infos{
  margin-bottom: 32px;
}
.pitch-link-button {
  color: #7C6DE4;
  font-weight: bold;
  margin-right: 100px;
  padding: 0;
}
.pitch-link-button img {
  margin-top: -3px;
  height: 20px;
  width: 20px;
  margin-right: 4px;
}
.additionalInfo-link-button {
  color: #7C6DE4;
  font-weight: bold;
  padding: 0;
}
.additionalInfo-link-button img{
  margin-top: -3px;
  height: 15px;
  width: 20px;
  margin-right: 4px;
}