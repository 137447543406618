.category {
    margin-bottom: 32px;
}
.category-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 18px;
}
.category-holder {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -15px; */
  justify-content: flex-start;
}
