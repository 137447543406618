.NewsCard {
    position: relative;
}

.NewsCard-hover-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary-blue);
    border-radius: 9px;
    cursor: pointer;
    opacity: 0;
    transition: all .1s linear;
}

.NewsCard-hover-overlay span{
    font-size: 2rem;
    color: white;
    font-weight: bold;
    transform: translateY(-10px);
    transition: all .1s linear;
}

.NewsCard:hover .NewsCard-hover-overlay {
    opacity: .9;
    transition: all .1s linear;
}

.NewsCard:hover .NewsCard-hover-overlay span {
    transform: translateY(0);
    transition: all .1s linear;
}

.News-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 1.1em;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.059);
    border-radius: 4px;
    overflow: hidden;
    font-size: 1.2rem;
}

.NewsCard-head-left {
    display: flex;
}

.News-action-rating .ant-rate-star-full i{
    color: var(--primary-blue);
}

.News-action-rating .ant-rate-star-zero i{
    color: #d3ccff;
}

.News-action {
    flex: 1;
    text-align: center;
    padding: .5em;
    cursor: pointer;
    color: var(--primary-blue);
}

.News-action:not(.disabled):hover {
    background-color: var(--primary-blue);
    color: white;
}

.News-action.comment.disabled,
.News-action.share.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: rgb(228, 228, 228);
}

@media (max-width: 512px) {
    .News-action {
        padding-left: 2px;
        padding-right: 2px;
    }

    .News-actions {
        margin-top: 11px;
    }
}

.News-social {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.1em;
    font-weight: bold;
    font-size: 15px;
}

.News-social-rating i {
    color: var(--primary-blue);
    margin-right: 4px;
}

.News-social-comments {
    margin-left: auto;
    margin-right: 30px;
    position: relative;
}

.News-social-comments::after {
    content: "";
    position: absolute;
    background-color: #595959;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: 10px;
    right: -18px;
}


@media (max-width: 512px) {
    .News-social {
        font-size: 14px;
    }

    .News-social-rating {
        flex-basis: 100%;
        margin-bottom: 5px;
    }

    .News-social-comments {
        margin-left: unset;
    }
}

/* card body */
.NewsCard-head {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
}

.NewsCard-profileImage {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    margin-bottom: 16px;
    margin-top: 4px;
}

.NewsCard-profileImage > img {
    border-radius: 100%;
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.NewsCard {
    word-break: break-word;
}

.NewsCard-head-data {
    line-height: 1.45;
}

.NewsCard-name {
    font-size: 15px;
}

.NewsCard-body .img{
    height: 260px;
    border: 1px solid #f1f1f1;
    width: 100%;
    background-size: cover;
    border-radius: 7px;
    background-position: 50% 45%;
}

.NewsCard-body img{
    max-height: 260px;
    border: 1px solid #f1f1f1;
    width: 100%;
    object-fit: cover;
    border-radius: 7px;
}

.NewsCard-body p {
    word-break: break-word;
}

.NewsCard-hashtags {
    margin-top: 6px
}

.NewsCard-hashtag {
    box-shadow: rgba(0, 0, 0, 0.04) -3px 2px 2px, rgba(0, 0, 0, 0.04) 2px 4px 10px, rgba(0, 0, 0, 0.03) 4px -8px 12px, rgba(0, 0, 0, 0.03) 8px 16px 16px, rgba(0, 0, 0, 0.02) 16px 32px 32px, rgba(0, 0, 0, 0.02) 32px 64px 64px;
    display: inline-block;
    padding: 2px 4px;
}

.NewsCard-name {
    font-weight: 800;
    color: #000 !important;
}

.NewsCard-roleName {
    font-size: 12px;
}

.NewsCard-createdAt {
    font-size: 12px;
}

.NewsCard-tags {
    display: inline-block;
    margin-top: 16px;
}

.NewsCard-tag {
    display: inline-block;
    margin-right: 32px;
    margin-bottom: 8px;
    font-weight: bold;
    color: var(--primary-blue)
}

.NewsCard h2 {
    font-weight: bold;
    font-size: 16px;
}

.NewsCard {
    border: 2px solid #f2f2f2;
    border-radius: 0;
    margin-top: 0;
    padding: 16px;
}

.NewsCard:first-child {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.NewsCard:last-child {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.NewsCard:not(:last-child) {
    border-bottom: 0;
}

.NewsCard-tagicon {
    width: 16px;
    height: 16px;
    background-size: contain;
    margin: 2px 9px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: white;
}

.NewsCard-link-tab {
    color: #000
}

.NewsCard-link-tab-bottom {
    padding-top: 4px;
}

.NewsCard-link-tab .img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
}

.NewsCard-link-tab-bottom {
    background: #F6F6F6;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.NewsCard-link-title {
    font-weight: 800;
    padding: 0px 4px 6px 8px;
}

.NewsCard-link-description {
    padding-left: 8px;
    padding-bottom: 6px;
    padding-right: 4px;
}