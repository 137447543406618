.popup-container{
    width:440px;
    height: 150px;
    display: flex; 
    flex-direction: column;
    position: relative;
    background: #6755CE;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding:24px;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    left: 40;
    right: 5%;
    top:70%;
    /* margin-left: 70% ;
    margin-top: 35%; */
}
.popup-content{
    display:flex;
    flex-direction: row;
}
.complete{
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF ;
    cursor: pointer;
    text-decoration: none;
}

.popup-content{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #E1DBFF;
}
.popup-text{
    display: flex;
    flex-direction: row;
}
.compelete-percantage{
    display: flex;
    flex-direction: row;
}
.popup-close-icon{
    filter: invert(1);
    width: 24px;
    height: 24px;
    margin-left: 20px;
    cursor: pointer;
}
.percantage{
    font-weight: 800;
    color: #FFFFFF;
}
.profile-link{
    text-decoration: none;
    color:rgb(255, 255, 255) !important
}

@media (max-width: 500px) {
    .popup-container{
        width: 80%;
        height: 220px;
        top:60%;
        left:0;
        right: 0;
    }
    
}